import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SliderSami from "../Sliders/SliderSami";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { xl, lg, md, sm, xs } from "./ConstantesTamanoLayout";
import BarrasGoogle from "./BarrasGoogle";
import BotonCerrarTarjeta from "./BotonCerrarTarjeta";
import Tabla5Columnas from "./Tabla5Columnas";
import TextFlieldSami from "./TextFlieldSami";
import layoutState from "../Recoil/Atoms/layoutState";
import { useRecoilState } from "recoil";
import calculosState from "../Recoil/Atoms/CalculosState";
import procesaRecalculos from '../Recoil/funciones/procesaRecalculos'
import cambiosNoGuardadosState from "../Recoil/Atoms/cambiosNoGuardadosState";


const TarjetaSlider = ({
  titulo,
  campoIncremento,
  campoIndicador1,
  campoIndicador2,
  campoIndicador3,
  tipoSlider,
  nombreTarjeta,
  adorno = "",
}) => {


  const [layout, setLayout] = useRecoilState(layoutState);
  const switches = layout

  const [ultimoResultado, setUltimoResultado] = useRecoilState(calculosState);
  const [cambiosNoGuardados, setCambiosNoGuardados] = useRecoilState(cambiosNoGuardadosState)

  let mostrarTarjeta = layout[nombreTarjeta];

  let incremento = ultimoResultado[campoIncremento];
  let indicador1 = ultimoResultado[campoIndicador1];
  let indicador2 = ultimoResultado[campoIndicador2];
  let indicador3 = ultimoResultado[campoIndicador3];

  let maximo = 10;

  if (incremento >= 10) maximo = 100;
  if (incremento >= 100) maximo = 500;
  if (incremento >= 500) maximo = 1000;

  //Colores
  let backgroundColor;
  if (tipoSlider === "marketing") {
    backgroundColor = "#fafaff";
  }
  if (tipoSlider === "ventas") {
    backgroundColor = "#fafffa";
  }
  if (tipoSlider === "utilidades") {
    backgroundColor = "#fffafa";
  }

  const incrementaSlider = (event, newValue) => {
    procesaRecalculos(
      campoIncremento,
      newValue,
      ultimoResultado,
      setUltimoResultado,
      setCambiosNoGuardados
    );
  }

  return (
    <>
      {mostrarTarjeta && (
        <Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
          <Card mb={3} sx={{ background: backgroundColor, width: "100%" }}>
            <CardContent sx={{ width: "100%" }}>
              <Grid container columns={12} sx={{ width: "100%" }}>
                <Grid
                  item
                  sm={8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <Typography variant="subtitle1">{titulo}</Typography>
                </Grid>

                <Grid
                  item
                  sm={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <BotonCerrarTarjeta nombreTarjeta={nombreTarjeta} />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item sm={9}>
                  <BarrasGoogle
                    titulo={titulo}
                    valor1={indicador1}
                    valor2={indicador2}
                    valor3={indicador3}
                  />
                </Grid>
              </Grid>
              <br />

              <Grid
                container
                columns={4}
                spacing={1}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item sm={1}>
                  <Grid container direction="column">
                    <Grid item sm={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "center" }}
                      >
                        Anterior:
                      </Typography>
                    </Grid>
                    <TextFlieldSami
                      nombreCampo={campoIndicador1}
                      value={indicador1}
                      adorno={adorno}
                    />
                  </Grid>
                </Grid>

                {switches.MOSTRAR_INCREMENTO && (
                  <Grid item sm={1}>
                    <Grid container direction="column">
                      <Grid item sm={1}>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "center" }}
                        >
                          Variación:
                        </Typography>
                      </Grid>
                      <TextFlieldSami
                        nombreCampo={campoIncremento}
                        value={incremento}
                        adorno={"%"}
                      />
                    </Grid>
                  </Grid>
                )}

                {switches.MOSTRAR_META && (
                  <Grid item sm={1}>
                    <Grid container direction="column">
                      <Grid item sm={1}>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "center" }}
                        >
                          Meta:
                        </Typography>
                      </Grid>
                      <TextFlieldSami
                        nombreCampo={campoIndicador2}
                        value={indicador2}
                        adorno={adorno}
                      />
                    </Grid>
                  </Grid>
                )}

                {switches.MOSTRAR_REAL && (
                  <Grid item sm={1}>
                    <Grid container direction="column">
                      <Grid item sm={1}>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "center" }}
                        >
                          Real:
                        </Typography>
                      </Grid>
                      <TextFlieldSami
                        nombreCampo={campoIndicador3}
                        value={indicador3}
                        adorno={adorno}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <SliderSami
                max={maximo}
                onChangeCommitted={(event, newValue) => {
                  incrementaSlider(event, newValue);
                }
                }
                category={tipoSlider}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default TarjetaSlider;
