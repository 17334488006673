import { selector, useRecoilState, useRecoilValue } from 'recoil';
import textSelectorState from './textSelectorState';

import React from 'react'

const mayus = selector({
    key: 'mayus',
    get: ({ get }) => {
        const me = get(textSelectorState)
        return me.toUpperCase()
    }
})

const Mayusculas = () => {
    const textoMayus = useRecoilValue(mayus)
    return (
        <h3>
            {textoMayus}
        </h3>
    )
}



const inputTextSelector = () => {

    const [textSelector, setTextSelector] = useRecoilState(textSelectorState);






    return (
        <div>
            <h5>Introduce texto:</h5>
            <input type="text" value={textSelector} onChange={(e) => { setTextSelector(e.target.value) }} />


            <h4>Texto cambiado</h4>
            <Mayusculas/>

        </div>
    )
}

export default inputTextSelector
