import React from "react";
import Chart from "react-google-charts";
import layoutState from '../Recoil/Atoms/layoutState';
import { useRecoilValue } from 'recoil';

const BarrasGoogle = ({ titulo, valor1, valor2, valor3 }) => {


  const switches = useRecoilValue(layoutState)

  let datos = [
    ["Indicador", "Anterior"],
    [titulo, valor1],
  ]
  if (switches.MOSTRAR_META && switches.MOSTRAR_REAL) {
    datos = [
      ["Indicador", "Anterior", "Meta", "Real"],
      [titulo, valor1, valor2, valor3],
    ]
  } else if (switches.MOSTRAR_META && !switches.MOSTRAR_REAL) {
    datos = [
      ["Indicador", "Anterior", "Meta"],
      [titulo, valor1, valor2],
    ]
  } else if (!switches.MOSTRAR_META && switches.MOSTRAR_REAL) {
    datos = [
      ["Indicador", "Anterior", "Real"],
      [titulo, valor1, valor3],
    ]
  }


  return (
    <Chart
      height={"200px"}
      width={"300px"}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={datos}
      options={{
        // Mate4rial design options

        legend: { position: "none" },
        vAxis: {
          viewWindow: {
            min: 0,
          },
        },
        chart: {
          title: "",
          subtitle: "",
        },

        backgroundColor: {
          fillOpacity: 0,
        },
      }}
      
      rootProps={{ "data-testid": "2" }}
    />
  );
};

export default BarrasGoogle;
