import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, MemoryRouter } from "react-router-dom";

import Ventas from "./Componentes/Pruebas/Ventas";
import TableroResultados from "./Componentes/Tableros/TableroResultados";
import Presentador from "./Componentes/Pruebas/Presentador";
import ProbadorRedux from "./Componentes/Pruebas/ProbadorRedux";

import { Provider } from "react-redux";

import SideBarSami from "./Componentes/Layout/SideBarSami";
import DrawerHeader from "./Componentes/Layout/DrawerHeader";

import { drawerWidth } from "./Componentes/Layout/drawerWidth";
import ToolBarSami from "./Componentes/Layout/ToolBarSami";

import LoginForm from "./Componentes/Login/LoginForm";

import ParaEventoOnBlur from "./Componentes/Pruebas/ParaEventoOnBlur";

import GeneradoresDeRiqueza from "./Componentes/Tableros/GeneradoresDeRiqueza";
import LortuAcordeon from "./Componentes/Tableros/LortuAcordeon";

import InputTextSelector from "./Componentes/Pruebas/pruebaSelectorRecoil/inputTextSelector";
import { useRecoilValue, useRecoilState } from "recoil";
import userState from "./Componentes/Recoil/Atoms/userState";

const PanelContenido = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));





export default function Principal() {

  

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const user = useRecoilValue(userState)



  return (
    <MemoryRouter initialEntries={["/drafts"]} initialIndex={0}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <ToolBarSami handleDrawerOpen={handleDrawerOpen} open={open} />
        <SideBarSami
          handleDrawerClose={handleDrawerClose}
          open={open}
          theme={theme}
        />

        {user.logeado&&
          <PanelContenido open={open}>
          <DrawerHeader />
          

          {/* <LortuAcordeon/>
            <br /><br /> */}

          <TableroResultados theme={theme} />
          

          {/* 
            <ProbadorRedux />
            <Presentador />
            <InputTextSelector />
            
            <Presentador />

            <ProbadorRedux />
            <LoginForm /> */}
        </PanelContenido>}
      </Box>
    </MemoryRouter>
  );
}
