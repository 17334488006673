import firebaseApp from "../../Firebase/firebaseSami";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithRedirect,
    GoogleAuthProvider,
  } from "firebase/auth";
  
  const initialState = {
    logeado: false,
    uid: "",
    nivel: "",
    nombre: "",
    email: ""
  };


  const intentaLogout = (setUser, setAnchorEl) => {

    const auth = getAuth(firebaseApp);

    try {
      auth.signOut();
      setUser(initialState)
      setAnchorEl(null);
    } catch (error) {
      console.log("Error en intenta Logout", error);
    }
  }

  export default intentaLogout;