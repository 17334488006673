import React from "react";
import GaugeChart from "react-gauge-chart";
import Thermometer from "react-thermometer-component";
import BarraDeProgreso from "./BarraDeProgreso";
import Box from "@mui/material/Box";

const IndicadorSami = ({ valor = 0, tipoIndicador = 1 }) => {
  // let aguja = valorGraficar / 100;

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",

      }}
    >
      {/* tipoIndicador == 1 && (
        <Box sx={{ textAlign: "center", margin: "auto" }}>
          <GaugeGoogle valorGraficar={valorGraficar} />
        </Box>
      ) */}

      {tipoIndicador == 1 && (
        <GaugeChart
          id="gauge-chart1"
          nrOfLevels={20}
          cornerRadius={0}
          needleColor="#ccc"
          arcWidth={0.4}
          hideText
          percent={valor}
          arcPadding={0}
          textColor={"#000"}
          style={{
            width: "100%",
            margin: "0",
            position: "relative",
            bottom: "-20px",
            marginBottom: 2

          }}
        />
      )}

      {tipoIndicador == 2 && (
        <GaugeChart
          id="tipo2-1"
          cornerRadius={0}
          needleColor="#ccc"
          arcWidth={0.4}
          percent={valor}
          arcPadding={0.05}
          textColor={"#000"}
          nrOfLevels={10}
          hideText
          style={{
            width: "100%",
            margin: "0",
            position: "relative",
            bottom: "-20px",
            marginBottom: 2

          }}
        />
      )}

      {tipoIndicador == 3 && (
        <GaugeChart
          id="tipo2-1"
          needleColor="#ccc"
          percent={valor}
          textColor={"#000"}
          hideText
          nrOfLevels={10}
          style={{
            width: "100%",
            margin: "0",
            position: "relative",
            bottom: "-20px",
            marginBottom: 2

          }}
        />
      )}
      {tipoIndicador == 4 && (
        <GaugeChart
          id="tipo2-1"
          needleColor="#ccc"
          percent={valor}
          arcsLength={[0.5, 0.25, 0.25]}
          textColor={"#000"}
          arcWidth={0.25}
          hideText
          borderRadius={10}
          style={{
            width: "100%",
            margin: "0",
            position: "relative",
            bottom: "-20px",
            marginBottom: 2

          }}
        />
      )}
      {tipoIndicador == 5 && (
        <GaugeChart
          id="gauge-chart3"
          style={{
            width: "100%",
            marginBottom: 2
          }}
          nrOfLevels={10}
          arcWidth={0.4}
          textColor={"#000"}
          arcPadding={0.15}
          cornerRadius={3}
          percent={valor}
          needleColor="#ff9a9a"
        />
      )}

      {tipoIndicador == 6 && (
        <GaugeChart
          id="gauge-chart4"
          style={{ width: "100%", marginBottom: 2 }}
          nrOfLevels={10}
          textColor={"#000"}
          arcPadding={0.05}
          colors={["#23D0FF", "#0904A0"]}
          cornerRadius={3}
          percent={valor}
          needleColor="#9a9aff"
        />
      )}
      {tipoIndicador == 7 && (
        <Thermometer
          theme="light"
          value={valor}
          max="100"
          format="%"
          size="large "
          height="150"
        />
      )}
      {tipoIndicador == 8 && <BarraDeProgreso percentage={value} />}
    </Box>
  );
};

export default IndicadorSami;
