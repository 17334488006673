import GaugeChart from "react-gauge-chart";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import IndicadorSami from "./IndicadorSami";
import { useSelector } from 'react-redux';
import { useRecoilValue } from "recoil";
import layoutState from "../Recoil/Atoms/layoutState";

const OdometroTripe = ({
  valor1 = 0,
  valor2 = 0,
  valor3 = 0,
  tipoIndicador = 1,
}) => {
  const redondea = (numero) => {
    return Math.round(numero * 100) / 100;
  };

  let maximo = valor1;
  if (valor2 > maximo) maximo = valor2;
  if (valor3 > maximo) maximo = valor3;
  //maximo = maximo * 1.05;
  let valor1F = redondea(valor1 / maximo);
  let valor2F = redondea(valor2 / maximo);
  let valor3F = redondea(valor3 / maximo);

  let formatoDeTexto = (value) => {
    // if (simboloDinero) return "$ " + value;
    // if (simboloPorcentaje) return value + "%";
    // else
    return value;
  };


  const switches = useRecoilValue(layoutState)

  let tamañoDeGauge = 7;
  if (!switches.MOSTRAR_META && !switches.MOSTRAR_REAL) tamañoDeGauge = 10

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "baseline",
        width: "100%",
        padding:"0px",
        
      }}
      columns={26}
    >
      <Grid
        item
        sm={tamañoDeGauge}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          left: "50px",
          
        }}
      >
        <IndicadorSami valor={valor1F} tipoIndicador={tipoIndicador} />
        <Typography variant="h6" m={0}>
          {valor1}
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
          Actual
        </Typography>
      </Grid>

      {switches.MOSTRAR_META && (
        <Grid
          item
          sm={10}
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <IndicadorSami valor={valor2F} tipoIndicador={tipoIndicador} />

          <Typography variant="h6" m={0}>
            {valor2}
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
            Meta
          </Typography>
        </Grid>)}

      {switches.MOSTRAR_REAL && (
        <Grid
          item
          sm={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            right: "50px",
          }}
        >
          <IndicadorSami valor={valor3F} tipoIndicador={tipoIndicador} />

          <Typography variant="h6" m={0}>
            {valor3}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left" }}>
            Real
          </Typography>
        </Grid>)}
    </Grid>
  );
};

export default OdometroTripe;
