const procesaRecalculos = (
  nombreCampo,
  valor,
  calculosAnteriores,
  setCalculos,
  setCambiosNoGuardados
) => {
  let im1 = 0, im2 = 0, im_inc = 0, imr = 0, p1 = 0, p_inc = 0, p2 = 0, pr = 0, tc1 = 0, tc_inc = 0, tc2 = 0,
    tcr = 0, cn1 = 0, cn_inc = 0, cn2 = 0, cnr = 0, ca1 = 0, ca_inc = 0, ca2 = 0, car = 0, ct1 = 0, ct_inc = 0,
    ct2 = 0, ctr = 0, cp1 = 0, cp_inc = 0, cp2 = 0, cpr = 0, fc1 = 1, fc_inc = 0, fc2 = 0, fcr = 0, vt1 = 0,
    vt_inc = 0, vt2 = 0, vtr = 0, mb1 = 0, mb_inc = 0, mb2 = 0, mbr = 0, ub1 = 0, ub_inc = 0, ub2 = 0, ubr = 0,
    gf1 = 0, gf_inc = 0, gf2 = 0, gfr = 0, ua1 = 0, ua_inc = 0, ua2 = 0, uar = 0, un1 = 0, un_inc = 0, un2 = 0,
    unr = 0, imp1 = 0, imp_inc = 0, imp2 = 0, impr = 0, cpp1 = 0, cpp_inc = 0, cpp2 = 0, cppr = 0, cpc1 = 0,
    cpc_inc = 0, cpc2 = 0, cpcr = 0, pe1 = 0, pe_inc = 0, pe2 = 0, per = 0;

  const redondea = (numero) => {
    return Math.round(numero * 100) / 100;
  };

  const calculaResultadoMejora = (f1, inc) => {
    return (1 + inc / 100) * f1;
  };

  const calculaPorcentajeMejora = (f1, f2) => {
    return (f2 / f1 - 1) * 100;
  };

  let state = calculosAnteriores;

  // Aquí van los cálculos por separado

  const calculaCN = () => {
    cn1 = redondea(p1 * (tc1 / 100));
    cn2 = redondea(p2 * (tc2 / 100));
    cn_inc = calculaPorcentajeMejora(cn1, cn2);
  };

  const calculaCT = () => {
    ct1 = ca1 + cn1;
    ct2 = ca2 + cn2;
    ct_inc = calculaPorcentajeMejora(ct1, ct2);
  };

  const calculaVT = () => {
    vt1 = ct1 * fc1 * cp1;
    vt2 = ct2 * fc2 * cp2;
    vt_inc = calculaPorcentajeMejora(vt1, vt2);
  };

  const calculaUB = () => {
    ub1 = (mb1 * vt1) / 100;
    ub2 = (mb2 * vt2) / 100;
    ub_inc = calculaPorcentajeMejora(vt1, vt2);
  };

  const calculaUA = () => {
    ua1 = ub1 - gf1;
    ua2 = ub2 - gf2;
    ua_inc = calculaPorcentajeMejora(ua1, ua2);
  };

  const calculaUN = () => {
    un1 = ua1 - imp1;
    un2 = ua2 - imp2;
    un_inc = calculaPorcentajeMejora(un1, un2);
  };

  const calculaCPP = () => {
    cpp1 = im1 / p1;
    cpp2 = im2 / p2;
    cpp_inc = calculaPorcentajeMejora(cpp1, cpp2);
  };

  const calculaCPC = () => {
    cpc1 = im1 / cn1;
    cpc2 = im2 / cn2;
    cpc_inc = calculaPorcentajeMejora(cpc1, cpc2);
  };

  const calculaPE = () => {
    pe1 = gf1 / mb1;
    pe2 = gf2 / mb2;
    pe_inc = calculaPorcentajeMejora(pe1, pe2);
  };

  const copiaDatos = () => {
    (im1 = state.IM1),
      (im2 = state.IM2),
      (im_inc = state.IM_INC),
      (imr = state.IMR),
      (p1 = state.P1),
      (p2 = state.P2),
      (p_inc = state.P_INC),
      (pr = state.PR),
      (tc1 = state.TC1),
      (tc2 = state.TC2),
      (tc_inc = state.TC_INC),
      (tcr = state.TCR),
      (cn1 = state.CN1),
      (cn2 = state.CN2),
      (cn_inc = state.CN_INC),
      (cnr = state.CNR),
      (ca1 = state.CA1),
      (ca_inc = state.CA_INC),
      (ca2 = state.CA2),
      (car = state.CAR),
      (ct1 = state.CT1),
      (ct2 = state.CT2),
      (ct_inc = state.CT_INC),
      (ctr = state.CTR),
      (cp1 = state.CP1),
      (cp2 = state.CP2),
      (cp_inc = state.CP_INC),
      (cpr = state.CPR),
      (fc1 = state.FC1),
      (fc2 = state.FC2),
      (fc_inc = state.FC_INC),
      (fcr = state.FCR),
      (vt1 = state.VT1),
      (vt2 = state.VT2),
      (vt_inc = state.VT_INC),
      (vtr = state.VTR),
      (mb1 = state.MB1),
      (mb2 = state.MB2),
      (mb_inc = state.MB_INC),
      (mbr = state.MBR),
      (ub1 = state.UB1),
      (ub2 = state.UB2),
      (ub_inc = state.UB_INC),
      (ubr = state.UBR),
      (gf1 = state.GF1),
      (gf2 = state.GF2),
      (gf_inc = state.GF_INC),
      (gfr = state.GFR),
      (ua1 = state.UA1),
      (ua2 = state.UA2),
      (ua_inc = state.UA_INC),
      (uar = state.UAR),
      (un1 = state.UN1),
      (un2 = state.UN2),
      (un_inc = state.UN_INC),
      (unr = state.UNR),
      (imp1 = state.IMP1),
      (imp2 = state.IMP2),
      (imp_inc = state.IMP_INC),
      (impr = state.IMPR),
      (cpp1 = state.CPP1),
      (cpp2 = state.CPP2),
      (cpp_inc = state.CPP_INC),
      (cppr = state.CPPR),
      (cpc1 = state.CPC1),
      (cpc2 = state.CPC2),
      (cpc_inc = state.CPC_INC),
      (cpcr = state.CPCR),
      (pe1 = state.PE1),
      (pe2 = state.PE2),
      (pe_inc = state.PE_INC),
      (per = state.PER);
  };

  let nuevoEstado;

  const getNuevoEstado = () => {
    nuevoEstado = {
      IM1: redondea(im1),
      IM2: redondea(im2),
      IM_INC: redondea(im_inc),
      IMR: redondea(imr),
      P1: redondea(p1),
      P_INC: redondea(p_inc),
      P2: redondea(p2),
      PR: pr,
      TC1: redondea(tc1),
      TC_INC: redondea(tc_inc),
      TC2: redondea(tc2),
      TCR: redondea(tcr),
      CN1: redondea(cn1),
      CN2: redondea(cn2),
      CN_INC: redondea(cn_inc),
      CNR: cnr,
      CA1: redondea(ca1),
      CA_INC: redondea(ca_inc),
      CA2: redondea(ca2),
      CAR: redondea(car),
      CT1: redondea(ct1),
      CT2: redondea(ct2),
      CT_INC: redondea(ct_inc),
      CTR: redondea(ctr),
      CP1: redondea(cp1),
      CP2: redondea(cp2),
      CP_INC: redondea(cp_inc),
      CPR: redondea(cpr),
      FC1: redondea(fc1),
      FC2: redondea(fc2),
      FC_INC: redondea(fc_inc),
      FCR: redondea(fcr),
      VT1: redondea(vt1),
      VT2: redondea(vt2),
      VT_INC: redondea(vt_inc),
      VTR: redondea(vtr),
      MB1: redondea(mb1),
      MB2: redondea(mb2),
      MB_INC: redondea(mb_inc),
      MBR: redondea(mbr),
      UB1: redondea(ub1),
      UB2: redondea(ub2),
      UB_INC: redondea(ub_inc),
      UBR: redondea(ubr),
      GF1: redondea(gf1),
      GF2: redondea(gf2),
      GF_INC: redondea(gf_inc),
      GFR: redondea(gfr),
      UA1: redondea(ua1),
      UA2: redondea(ua2),
      UA_INC: redondea(ua_inc),
      UAR: redondea(uar),
      UN1: redondea(un1),
      UN2: redondea(un2),
      UN_INC: redondea(un_inc),
      UNR: redondea(unr),
      IMP1: redondea(imp1),
      IMP2: redondea(imp2),
      IMP_INC: redondea(imp_inc),
      IMPR: redondea(impr),
      CPP1: redondea(cpp1),
      CPP2: redondea(cpp2),
      CPP_INC: redondea(cpp_inc),
      CPPR: redondea(cppr),
      CPC1: redondea(cpc1),
      CPC2: redondea(cpc2),
      CPC_INC: redondea(cpc_inc),
      CPCR: redondea(cpcr),
      PE1: redondea(pe1),
      PE2: redondea(pe2),
      PE_INC: redondea(pe_inc),
      PER: redondea(per),
    };
    return nuevoEstado;
  };

  copiaDatos();
  valor = parseInt(valor);

  switch (nombreCampo) {


    case "IM1":
      im1 = valor;
      im2 = calculaResultadoMejora(im1, im_inc);

      calculaCPP();
      calculaCPC();
      calculaCN();
      calculaCT();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "IM_INC":
      im_inc = valor;
      im2 = calculaResultadoMejora(im1, im_inc);
      calculaCPP();
      calculaCPC();
      setCalculos(getNuevoEstado());
      break;

    case "IM2":
      im2 = valor;
      im_inc = ((im2 - im1) / im1) * 100;

      calculaCPP();
      calculaCPC();
      calculaCN();
      calculaCT();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "IMR":
      imr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "CT1":
      ct1 = valor;
      ct2 = calculaResultadoMejora(ct1, ct_inc);

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "CT_INC":
      ct_inc = valor;
      ct2 = calculaResultadoMejora(ct1, ct_inc);

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      setCalculos(getNuevoEstado());
      break;

    case "CT2":
      ct2 = valor;
      ct_inc = ((ct2 - ct1) / ct1) * 100;

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "CTR":
      ctr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "P1":
      p1 = valor;
      p2 = calculaResultadoMejora(p1, p_inc);

      
      calculaCN();
      calculaCT();
      calculaCPP();
      calculaCPC();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "P_INC":
      p_inc = valor;
      p2 = calculaResultadoMejora(p1, p_inc);

    
      calculaCN();
      calculaCT();
      calculaCPP();
      calculaCPC();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "P2":
      p2 = valor;
      p_inc = ((p2 - p1) / p1) * 100;

      
      calculaCN();
      calculaCT();
      calculaCPP();
      calculaCPC();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "PR":
      pr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "TC1":
      tc1 = valor;
      tc2 = calculaResultadoMejora(tc1, tc_inc);
      calculaCN();
      calculaCT();
      calculaCPP();
      calculaCPC();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "TC_INC":
      tc_inc = valor;
      tc2 = calculaResultadoMejora(tc1, tc_inc);

      calculaCN();
      calculaCT();
      calculaCPP();
      calculaCPC();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "TC2":
      tc2 = valor;
      tc_inc = ((tc2 - tc1) / tc1) * 100;

      calculaCN();
      calculaCT();
      calculaCPP();
      calculaCPC();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "TCR":
      tcr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "CA1":
      ca1 = valor;
      ca2 = calculaResultadoMejora(ca1, ca_inc);

      calculaCT();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();

      calculaCPP();
      calculaCPC();

      setCalculos(getNuevoEstado());
      break;

    case "CA_INC":
      ca_inc = valor;
      ca2 = calculaResultadoMejora(ca1, ca_inc);

      calculaCT();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();

      calculaCPP();
      calculaCPC();
      setCalculos(getNuevoEstado());
      break;

    case "CA2":
      ca2 = valor;
      ca_inc = ((ca2 - ca1) / ca1) * 100;

      calculaCT();
      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();

      calculaCPP();
      calculaCPC();
      setCalculos(getNuevoEstado());
      break;

    case "CAR":
      car = valor;
      setCalculos(getNuevoEstado());
      break;

    case "CP1":
      cp1 = valor;
      cp2 = calculaResultadoMejora(cp1, cp_inc);

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "CP_INC":
      cp_inc = valor;
      cp2 = calculaResultadoMejora(cp1, cp_inc);

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "CP2":
      cp2 = valor;
      cp_inc = ((cp2 - cp1) / cp1) * 100;

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "CPR":
      cpr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "FC1":
      fc1 = valor;
      fc2 = calculaResultadoMejora(fc1, fc_inc);

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "FC_INC":
      fc_inc = valor;
      fc2 = calculaResultadoMejora(fc1, fc_inc);

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "FC2":
      fc2 = valor;
      fc_inc = ((fc2 - fc1) / fc1) * 100;

      calculaVT();
      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "FCR":
      fcr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "MB1":
      mb1 = valor;
      mb2 = calculaResultadoMejora(mb1, mb_inc);

      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "MB_INC":
      mb_inc = valor;
      mb2 = calculaResultadoMejora(mb1, mb_inc);

      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "MB2":
      mb2 = valor;
      mb_inc = ((mb2 - mb1) / mb1) * 100;

      calculaUB();
      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "MBR":
      mbr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "GF1":
      gf1 = valor;
      gf2 = calculaResultadoMejora(gf1, gf_inc);

      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "GF_INC":
      gf_inc = valor;
      gf2 = calculaResultadoMejora(gf1, gf_inc);

      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "GF2":
      gf2 = valor;
      gf_inc = ((gf2 - gf1) / gf1) * 100;

      calculaUA();
      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "GFR":
      gfr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "IMP1":
      imp1 = valor;
      imp2 = calculaResultadoMejora(imp1, imp_inc);

      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "IMP_INC":
      imp_inc = valor;
      imp2 = calculaResultadoMejora(imp1, imp_inc);

      calculaUN();
      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "PE_INC":
      pe_inc = valor;
      pe2 = calculaResultadoMejora(pe1, pe_inc);

      calculaPE();
      setCalculos(getNuevoEstado());
      break;

    case "PER":
      per = valor;
      setCalculos(getNuevoEstado());
      break;

    // Fin de las acciones de los selectores disponibles ///////////////////
    // e inicio de los selectores reales no disponibles ///////////////////////

    case "CNR":
      cnr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "CPPR":
      cppr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "CPCR":
      cpcr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "VTR":
      vtr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "UBR":
      ubr = valor;
      setCalculos(getNuevoEstado());
      break;

    case "UAR":
      uar = valor;
      setCalculos(getNuevoEstado());
      break;

    case "UNR":
      unr = valor;
      setCalculos(getNuevoEstado());
      break;
  }

  
  
  let localstorageDatos = getNuevoEstado();
  setCambiosNoGuardados(true)
  localStorage.setItem('calculos', JSON.stringify(localstorageDatos));
  localStorage.setItem('localCambiosNoGuardados', true)
  

};


export default procesaRecalculos;