import { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useRecoilState, useRecoilValue } from "recoil";
import iconosDeLista from "../Recoil/Atoms/iconosDeLista";
import filtroTareaState from "../Recoil/Atoms/filtroTareaState";
import cambiosNoGuardadosState from "../Recoil/Atoms/cambiosNoGuardadosState";

export default function ElementoDeLista({ id, texto }) {
    const [iconos, setIconos] = useRecoilState(iconosDeLista);
    const filtro = useRecoilValue(filtroTareaState);
    const [cambiosNoGuardados, setCambiosNoGuardados] = useRecoilState(cambiosNoGuardadosState)

    const getEstadofromArray = () => {
        const resultado = iconos.find((item) => item.id === id);
        if (resultado) return resultado.estado;
        else return "⚪";
    };

    const actualizarEstado = (valor) => {
        let filtrado = iconos.filter((item) => item.id !== valor.id);
        if (valor.estado != "⚪")
            filtrado.push({ id: valor.id, estado: valor.estado });
        setIconos(filtrado);

        //Esto es para lo del mensaje de guardado
        localStorage.setItem('tareas', JSON.stringify(iconos));
        setCambiosNoGuardados(true);
    };

    const estado = getEstadofromArray();

    const handleChange = () => {
        var estadoAux = "";
        switch (estado) {
            case "⚪":
                estadoAux = "🎯";
                break;
            case "🎯":
                estadoAux = "✅";
                break;
            case "✅":
                estadoAux = "⏱";
                break;
            case "⏱":
                estadoAux = "❌";
                break;
            case "❌":
                estadoAux = "💎";
                break;
            case "💎":
                estadoAux = "⚪";
                break;

            default:
                estadoAux = "⚪";
        }


        actualizarEstado({ id: id, estado: estadoAux });
    };

    let mostrado = true;
    if (filtro && estado == "⚪") mostrado = false;

    const Señales = () => {
        return (
            <Typography variant="body1">
                ⚪ No considerando
                <br />
                🎯 Estamos enfocados en esto
                <br />
                ✅ Lo vamos a seguir haciendo
                <br />
                ⏱ Para más adelante
                <br />
                ❌ No nos funciona
                <br />
                💎 Recomendado por mi coach
                <br />
            </Typography>
        );
    };

    return (
        <>
            {mostrado && (

                <ListItem disablePadding dense>
                    <ListItemButton onClick={handleChange} sx={{ p: "0" }}>

                        <Tooltip title={<Señales />} arrow placement="bottom-start">
                            <Typography mr={1}>{estado}</Typography>
                        </Tooltip>

                        <ListItemText primary={texto} />

                    </ListItemButton>
                </ListItem>
            )}
        </>
    );
}
