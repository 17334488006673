import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PreviewIcon from '@mui/icons-material/Preview';
import Divider from '@mui/material/Divider';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import abrirCerrarMenu from '../Recoil/Atoms/AbrirCerrarMenu';
import { useRecoilState, useRecoilValue } from 'recoil';
import userState from '../Recoil/Atoms/userState';
import calculosState from '../Recoil/Atoms/CalculosState';
import intentaLogout from '../Recoil/funciones/intentaLogout';
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonIcon from "@mui/icons-material/Person";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import guardarDatos from '../../Firebase/guardarDatos';
import SaveIcon from '@mui/icons-material/Save';
import iconosDeLista from '../Recoil/Atoms/iconosDeLista';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ObtenerUltimosDatos from '../../Firebase/ObtenerUltimosDatos';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import cambiosNoGuardadosState from '../Recoil/Atoms/cambiosNoGuardadosState';



export default function MenuPrincipal() {

    const [cambiosNoGuardados, setCambiosNoGuardados] = useRecoilState(cambiosNoGuardadosState);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [user, setUser] = useRecoilState(userState);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [menu, setMenu] = useRecoilState(abrirCerrarMenu);
    const [calculos, setCalculos] = useRecoilState(calculosState);
    const [tareas, setTareas] = useRecoilState(iconosDeLista)



    const abreCierraTabla = () => {
        handleClose()
        setMenu({ ...menu, tabla: !menu.tabla })

    }

    const abreCierraTarjetas = () => {
        handleClose()
        setMenu({ ...menu, tarjetas: !menu.tarjetas })
    }

    const abreCierraLogin = () => {
        handleClose()
        setMenu({ ...menu, login: true })
    }

    const abreCierraTareas = () => {
        handleClose();
        setMenu({ ...menu, acciones: !menu.acciones })
    }

    const abreCierraNube = () => {
        setMenu({ ...menu, nube: !menu.nube });
        handleClose();
    }

    const cerrarSesion = () => {
        guardarDatos(calculos, user, tareas, setCambiosNoGuardados)
        intentaLogout(setUser, setAnchorEl);
    }




    const aceptoBorrar = () => {

        ObtenerUltimosDatos(user, setCalculos, setTareas);
        setCambiosNoGuardados(false);
        handleClose();
    }

    const guardar = () => {
        guardarDatos(calculos, user, tareas, setCambiosNoGuardados)
        handleClose();
    }


    return (
        <div>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                edge="start"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <MenuItem onClick={abreCierraTarjetas}>
                    <PreviewIcon />
                    Mostrar / ocultar tarjetas
                </MenuItem>


                <Divider />


                <MenuItem onClick={abreCierraTabla}>
                    <AssessmentOutlinedIcon />
                    Tabla de datos
                </MenuItem>

                <MenuItem onClick={abreCierraTareas}>
                    <TaskAltIcon />
                    Lista de acciones
                </MenuItem>


                <Divider />


                <MenuItem onClick={guardar}>
                    <CloudUploadIcon />
                    Guardar en la nube
                </MenuItem>

                <Divider />

                <MenuItem onClick={aceptoBorrar} disabled={cambiosNoGuardados ? true : false}>
                    <CloudDownloadIcon />
                    Obtener últimos datos de la nube <br />
                </MenuItem>

                <MenuItem onClick={abreCierraNube} disabled={cambiosNoGuardados ? true : false}>
                    <CloudDownloadIcon />
                    Escoger datos de la nube <br />
                </MenuItem>

                {
                    cambiosNoGuardados &&
                    <MenuItem disabled>
                        (Guardar o descartar los cambios para descargar de la nube)
                    </MenuItem>
                }

                <Divider />

                {
                    user.logeado

                        ? <MenuItem onClick={cerrarSesion}>
                            <LoginOutlinedIcon />
                            <b>Guardar y cerrar sesión</b>
                        </MenuItem>

                        : <MenuItem onClick={abreCierraLogin}>
                            <PersonIcon />
                            Login
                        </MenuItem>
                }



            </Menu>
        </div >
    );
}