import * as React from "react";
import ReactDOM from "react-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import Principal from "./Principal";
import "./app.css";
import { RecoilRoot } from "recoil";


ReactDOM.render(
  <StyledEngineProvider injectFirst>

    <RecoilRoot>
      <Principal />
    </RecoilRoot>

  </StyledEngineProvider>,

  document.querySelector("#root")
);
