import { atom } from "recoil";

const initialState = {
  IM1: 100,
  IM_INC: 10,
  IM2: 110,
  IMR: 105,
  P1: 100,
  P_INC: 10,
  P2: 110,
  PR: 105,
  TC1: 10,
  TC_INC: 10,
  TC2: 11,
  TCR: 11,
  CN1: 10,
  CN_INC: 10,
  CN2: 11,
  CNR: 10,
  CA1: 10,
  CA_INC: 10,
  CA2: 11,
  CAR: 11,
  CT1: 20,
  CT_INC: 10,
  CT2: 22,
  CTR: 20,
  CP1: 100,
  CP_INC: 0,
  CP2: 100,
  CPR: 10,
  FC1: 1,
  FC_INC: 0,
  FC2: 1,
  FCR: 10,
  VT1: 200,
  VT_INC: 0,
  VT2: 200,
  VTR: 200,
  MB1: 10,
  MB_INC: 0,
  MB2: 10,
  MBR: 10,
  UB1: 20,
  UB_INC: 0,
  UB2: 20,
  UBR: 20,
  GF1: 10,
  GF_INC: 0,
  GF2: 10,
  GFR: 10,
  UA1: 10,
  UA_INC: 0,
  UA2: 10,
  UAR: 10,
  IMP1: 1,
  IMP_INC: 0,
  IMP2: 0,
  IMPR: 0,
  UN1: 7,
  UN_INC: 0,
  UN2: 0,
  UNR: 0,
  N1: 0,
  N2: 0,
  N3: 0,
  NR: 0,
  CPP1: 20,
  CPP_INC: 0,
  CPP2: 20,
  CPPR: 20,
  CPC1: 200,
  CPC_INC: 0,
  CPC2: 200,
  CPCR: 200,
  PE1: 0,
  PE_INC: 0,
  PE2: 0,
  PER: 0,
};

const calculosState = atom({
  key: "calculosState", // unique ID (with respect to other atoms/selectors)
  default: initialState // default value (aka initial value)
});
export default calculosState;
