import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import obtenerUltimosDatos from "../../../Firebase/ObtenerUltimosDatos";
import firebaseApp from '../../Firebase/firebaseSami';

const intentaLogin = async (setUser, menu, setMenu, setCalculos, setTareas, setCambiosNoGuardados, cambiosNoGuardados) => {
    const auth = getAuth(firebaseApp);
    const googleProvider = new GoogleAuthProvider();

    await signInWithPopup(auth, googleProvider)
        .then((result) => {
            const datosUsuario = result.user;

            const newSesion = {
                logeado: true,
                uid: datosUsuario.uid,
                nivel: "1",
                nombre: datosUsuario.displayName,
                email: datosUsuario.email
            }

            setUser(newSesion);
            setMenu({ ...menu, login: false });
            //obtenerUltimosDatos(newSesion, setCalculos, setTareas);
        })
        .then(() => {

            try {
                let calculosLocal = localStorage.getItem('calculos');
                let calculosParsed;
                if (calculosLocal != null) {
                    calculosParsed = JSON.parse(calculosLocal)
                    try {
                        setCalculos(calculosParsed)
                    } catch (e) {
                        console.warn(e)
                    }

                }

                

                let tareasLocal = localStorage.getItem('tareas');
                let tareasParsed;
                if (tareasLocal != null) {
                    
                    tareasParsed = JSON.parse(tareasLocal)
                    console.log(tareasParsed)
                    try {
                        setTareas(tareasParsed)
                    } catch (e) {
                        console.warn(e)
                    }
                }

                let localCambiosNoGuardados = localStorage.getItem('localCambiosNoGuardados')
                if (localCambiosNoGuardados === 'true') {
                    setCambiosNoGuardados(true)
                }
            } catch (error) {
                console.warn(error)
            }

        })
        .catch((error) => {
            console.error("Error en intentaLogin-->", error);
        });
};

export default intentaLogin;