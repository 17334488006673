import {
    collection,
    setDoc,
    doc,
    getFirestore,
    serverTimestamp
  } from "firebase/firestore";
  import firebaseApp from "../Componentes/Firebase/firebaseSami";


const guardarDatos = async (datos, user, tareas, setCambiosNoGuardados) => {

    const db = getFirestore(firebaseApp);
    
    const direccionDatos = doc(collection(db, "usuarios5/" + user.email + "/datos"));
    await setDoc(direccionDatos, {
      "calculos": datos,
      "tareas": tareas,
      "timeStamp": serverTimestamp()
    });

    localStorage.setItem('localCambiosNoGuardados', JSON.stringify(false))
    setCambiosNoGuardados(false)

    localStorage.setItem('calculos', JSON.stringify(datos))
    localStorage.setItem('tareas', JSON.stringify(tareas))

  }
  export default guardarDatos;