import { atom } from "recoil";

let initialState = {
    tabla: false,
    tarjetas: false,
    acciones: false,
    nube: false
}

const AbrirCerrarMenu = atom({
    key: "abrirCerrarMenu", // unique ID (with respect to other atoms/selectors)
    default: initialState // default value (aka initial value)
  });
  export default AbrirCerrarMenu;
  