import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import AbrirCerrarMenu from '../Recoil/Atoms/AbrirCerrarMenu';
import layoutState from '../Recoil/Atoms/layoutState';
import { useRecoilState } from 'recoil';
import crearNuevoJson from '../Recoil/funciones/crearNuevoJson';
import filtroTareaState from '../Recoil/Atoms/filtroTareaState';



const theme = createTheme({
    palette: {
        secondary: {
            main: "#2a2aaa",
        },
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SwitchesTarjetas() {

    const [open, setOpen] = React.useState(false);

    const [menu, setMenu] = useRecoilState(AbrirCerrarMenu);
    const [layout, setLayout] = useRecoilState(layoutState);
    const switches = layout;
    const [filtroTareas, setFiltroTareas] = useRecoilState(filtroTareaState)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        //switchTarjeta('SWITCHES_DIALOG')
        setMenu({ ...menu, tarjetas: !menu.tarjetas })
    };

    const switchTarjeta = (tarjeta) => {
        let obj = crearNuevoJson(tarjeta, !layout[tarjeta])
        setLayout({ ...layout, ...obj })
    }

    const mostrarTarjetasSilders = () => {
        let obj = {
            IM: true,
            P: true,
            TC: true,
            CA: true,
            CP: true,
            FC: true,
            MB: true,
            GF: true,
            IMP: true
        }
        setLayout({ ...layout, ...obj })
    }

    const ocultarTarjetasSilders = () => {
        let obj = {
            IM: false,
            P: false,
            TC: false,
            CA: false,
            CP: false,
            FC: false,
            MB: false,
            GF: false,
            IMP: false
        }
        setLayout({ ...layout, ...obj })
    }

    const mostrarTarjetasOdometros = () => {
        let obj = {
            CN: true,
            CPP: true,
            CPC: true,
            CT: true,
            VT: true,
            UB: true,
            UA: true,
            UN: true,
            PE: true,
        }
        setLayout({ ...layout, ...obj })
    }

    const ocultarTarjetasOdometros = () => {
        let obj = {
            CN: false,
            CPP: false,
            CPC: false,
            CT: false,
            VT: false,
            UB: false,
            UA: false,
            UN: false,
            PE: false,
        }
        setLayout({ ...layout, ...obj })
    }


    return (
        <div>
            {/* <ThemeProvider theme={theme}>
                <Button color="secondary" variant="contained" onClick={handleClickOpen}>
                    Mostrar / ocultar tarjetas
                </Button>
            </ThemeProvider> */}
            <Dialog
                open={menu.tarjetas}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Mostrar / ocultar tarjetas</DialogTitle>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }} >
                    <Grid container columns={2} spacing={1} > {/* Este es el contenedor de todo */}
                        <Grid item sm={1}>
                            <Grid container direction="column" columns={2} sx={{ width: "100%" }}>
                                <Grid item xs={1} sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",

                                }}>
                                    <Button variant="contained" onClick={() => mostrarTarjetasSilders()}>
                                        Mostrar todos
                                    </Button>
                                </Grid>
                                <Grid item xs={1} sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",

                                }}>
                                    <Button variant="outlined" onClick={() => ocultarTarjetasSilders()}>
                                        Ocultar todos
                                    </Button>
                                </Grid>
                            </Grid>

                            <FormGroup>
                                <Grid container direction="column" columns={2}>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.IM}
                                                onClick={() => { switchTarjeta('IM') }} />}
                                            label="Inversión en marketing" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.P}
                                                onClick={() => { switchTarjeta('P') }} />}
                                            label="Prospectos" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.TC}
                                                onClick={() => { switchTarjeta('TC') }} />}
                                            label="Tasa de conversión" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.CA}
                                                onClick={() => { switchTarjeta('CA') }} />}
                                            label="Clientes anteriores" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.CP}
                                                onClick={() => { switchTarjeta('CP') }} />}
                                            label="Ticket promedio" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.FC}
                                                onClick={() => { switchTarjeta('FC') }} />}
                                            label="Frecuencia de compra" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.MB}
                                                onClick={() => { switchTarjeta('MB') }} />}
                                            label="Margen bruto" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.GF}
                                                onClick={() => { switchTarjeta('GF') }} />}
                                            label="Gastos fijos" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.IMP}
                                                onClick={() => { switchTarjeta('IMP') }} />}
                                            label="Impuestos" />
                                    </Grid>
                                    <br /><br />
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.MOSTRAR_INCREMENTO}
                                                onClick={() => { switchTarjeta('MOSTRAR_INCREMENTO') }} />}
                                            label="Mostrar incrementos" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.MOSTRAR_REAL}
                                                onClick={() => { switchTarjeta('MOSTRAR_REAL') }} />}
                                            label="Mostrar real" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.MOSTRAR_META}
                                                onClick={() => { switchTarjeta('MOSTRAR_META') }} />}
                                            label="Mostrar meta" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={filtroTareas}
                                                onClick={() => { setFiltroTareas(!filtroTareas) }} />}
                                            label="Ocultar tareas no marcadas" />
                                    </Grid>

                                </Grid>
                            </FormGroup>
                        </Grid>
                        <Grid item sm={1}>
                            <Grid container direction="column" columns={2} >
                                <Grid item xs={1} sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",

                                }}>
                                    <Button variant="contained" onClick={() => mostrarTarjetasOdometros()}>
                                        Mostrar todos
                                    </Button>
                                </Grid>
                                <Grid item xs={1} sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",

                                }}>
                                    <Button variant="outlined" onClick={() => ocultarTarjetasOdometros()}>
                                        Ocultar todos
                                    </Button>
                                </Grid>
                            </Grid>

                            <FormGroup>
                                <Grid container direction="column" columns={2}>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.CN}
                                                onClick={() => { switchTarjeta('CN') }} />}
                                            label="Clientes nuevos" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.CPP}
                                                onClick={() => { switchTarjeta('CPP') }} />}
                                            label="Costo por prospecto" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.CPC}
                                                onClick={() => { switchTarjeta('CPC') }} />}
                                            label="Costo por cliente" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.CT}
                                                onClick={() => { switchTarjeta('CT') }} />}
                                            label="Clientes totales" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.VT}
                                                onClick={() => { switchTarjeta('VT') }} />}
                                            label="Ventas totales" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.UB}
                                                onClick={() => { switchTarjeta('UB') }} />}
                                            label="Utilidad bruta" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.UA}
                                                onClick={() => { switchTarjeta('UA') }} />}
                                            label="Utilidad antes de impuestos" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.UN}
                                                onClick={() => { switchTarjeta('UN') }} />}
                                            label="Utilidad neta" />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={switches.PE}
                                                onClick={() => { switchTarjeta('PE') }} />}
                                            label="Punto de equilibrio" />
                                    </Grid>

                                </Grid>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}