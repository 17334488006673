import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
const Tabla5Columnas = ({ col1, col2, col3, col4, col5 }) => {
  return (
    <Grid
      container
      spacing={1}
      columns={5}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item sm={1}>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          {col1}
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {col2}
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {col3}
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {col4}
        </Typography>
      </Grid>
      <Grid item sm={1}>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {col5}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Tabla5Columnas;
