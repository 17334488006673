import { atom } from "recoil";

const initialState = {
  logeado: false,
  uid: "",
  nivel: "",
  nombre: "",
  email: ""
};

const userState = atom({
  key: "userState", // unique ID (with respect to other atoms/selectors)
  default: initialState, // default value (aka initial value)
});
export default userState;