import * as React from "react";
import { useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import RegistroDialog from "./RegistroDialog";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useRecoilState } from "recoil";
import userState from "../Recoil/Atoms/userState";
import calculosState from "../Recoil/Atoms/CalculosState";
import AbrirCerrarMenu from "../Recoil/Atoms/AbrirCerrarMenu";
import intentaLogin from "../Recoil/funciones/intentaLogin";
import intentaLoginUsrPwd from "../Recoil/funciones/intentaLoginUsrPwd";
import googleLogo from "./google-g-2015.svg";
import diamante from '../img/diamante.png';
import iconosDeLista from "../Recoil/Atoms/iconosDeLista";
import cambiosNoGuardadosState from "../Recoil/Atoms/cambiosNoGuardadosState";



const LoginForm = ({ navBar = false }) => {
  const [menu, setMenu] = useRecoilState(AbrirCerrarMenu);
  const [user, setUser] = useRecoilState(userState);
  const [calculos, setCalculos] = useRecoilState(calculosState);
  const [tareas, setTareas] = useRecoilState(iconosDeLista)
  const [cambiosNoGuardados, setCambiosNoGuardados] = useRecoilState(cambiosNoGuardadosState)
  const [mostrarEmailPwd, setMostrarEmailPwd] = useState(false)


  const [email, setEmail] = React.useState(""); // prueba@prueba.com
  const [password, setPassword] = React.useState(""); // prueba







  const actionForm = (e) => {
    e.preventDefault();
    intentaLoginUsrPwd(setUser, menu, setMenu, email, password, setCalculos, setTareas, setCambiosNoGuardados);
    setEmail("");
    setPassword("");
  };

  const ingresaGoogle = () => {
    intentaLogin(setUser, menu, setMenu, setCalculos, setTareas, setCambiosNoGuardados, cambiosNoGuardados);
  }


  return (

    <Dialog open={!user.logeado} onClose={() => setMenu({ ...menu, login: false })} maxWidth="xs" >
      <DialogTitle sx={{ textAlign: "center", }}>
        Bienvenido(a) <br />
        <img src={diamante} alt="logo3pilares" width="60px" /> <br />

      </DialogTitle>

      <DialogContent >
        <DialogContentText>Inicie sesión para continuar:</DialogContentText>

        {mostrarEmailPwd && (
          <>
            <form onSubmit={actionForm}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Correo electrónico"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                margin="dense"
                id="name"
                label="Contraseña"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button variant="contained" fullWidth type="submit">
                Iniciar sesión
              </Button>
            </form>
            <br />

            <Divider>¿No tienes cuenta?</Divider>
            <RegistroDialog />
            <br />
            <br />

            <Divider>Tienes una cuenta de Google?</Divider>
            <Button fullWidth onClick={() => setMostrarEmailPwd(false)}>Inicia con tu cuenta de google</Button>


            <br />
          </>)
        }



        {!mostrarEmailPwd && (
          <>
            <Button
              size="large"
              variant="outlined"
              fullWidth
              sx={{ padding: 2 }}
              onClick={ingresaGoogle}
              startIcon={<img src={googleLogo} width="25px" />}
            >
              Ingresar con google
            </Button>
            <br />
            <br />
            <Divider>¿No tienes cuenta de google?</Divider>
            <Button fullWidth size="small" onClick={() => { setMostrarEmailPwd(true) }} >
              Ingresar con correo y contraseña
            </Button>
          </>
        )
        }


      </DialogContent>

    </Dialog>

  );
};
export default LoginForm;
