import * as React from 'react';
import Button from '@mui/material/Button';
import SnackbarContent from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState, useRecoilValue } from 'recoil';
import cambiosNoGuardadosState from '../Recoil/Atoms/cambiosNoGuardadosState';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ObtenerUltimosDatos from '../../Firebase/ObtenerUltimosDatos';
import userState from '../Recoil/Atoms/userState';
import guardarDatos from '../../Firebase/guardarDatos';
import calculosState from '../Recoil/Atoms/CalculosState';
import iconosDeLista from '../Recoil/Atoms/iconosDeLista';







export default function SnackbarCambiosSinGuardar() {


    const [cambiosNoGuardados, setCambiosNoGuardados] = useRecoilState(cambiosNoGuardadosState)
    const [pideBorrar, setPideBorrar] = React.useState(false)

    const user = useRecoilValue(userState);
    const [calculos, setCalculos] = useRecoilState(calculosState);
    const [tareas, setTareas] = useRecoilState(iconosDeLista)


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCambiosNoGuardados(false);
    };

    const guardar = ()=> {
        guardarDatos(calculos, user, tareas, setCambiosNoGuardados)
        handleClose()
    }

    const action = (
        <>
            <Button color="primary" variant="text" size="small" onClick={()=>setPideBorrar(true)}>
                <DeleteForeverIcon />
                Descartar todo
            </Button>

            <Button color="primary" variant="contained" size="small" onClick={guardar} ml={2}>
                <SaveIcon />
                Guardar cambios
            </Button>
        </>
    );


    const aceptoBorrar = () => {
        ObtenerUltimosDatos(user, setCalculos, setTareas);
        setPideBorrar(false);
        handleClose()
        
    }

 

    return (
        <>
            <SnackbarContent
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={cambiosNoGuardados}
                onClose={handleClose}
                message={'Hay cambios sin guardar en la nube. \ Guardar o descartar para obtener datos de ella.'}
                action={action}
            />


            <Dialog
                open={pideBorrar}
                onClose={() => { setPideBorrar(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Descartar cambios"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Borrar todos los cambios realizados
                        y volver a cargar los últimos datos guardados?
                        (Esta acción no se puede deshacer)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPideBorrar(false)} autoFocus>Regresar</Button>
                    <Button variant="contained" onClick={aceptoBorrar}>Borrar y restablecer</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}