import IOSSlider from "./IOSSlider";
import Slider from "@mui/material/Slider";
import PrettoSlider from "./PrettoSlider";

const marks = [
  {
    value: 20,
  },
  {
    value: 40,
  },
  {
    value: 60,
  },
  {
    value: 80,
  },
  {
    value: 100,
  },
];

const SliderSami = (props) => {
  return (
    <>
      {props.category == "marketing" && (
        <Slider
          {...props}
          marks={marks}
          aria-label="Volume"
          orientation="horizontal"
          valueLabelDisplay="auto"
        />
      )}
      {props.category == "ventas" && (
        <PrettoSlider
          {...props}
          marks={marks}
          aria-label="Volume"
          orientation="horizontal"
          valueLabelDisplay="auto"
        />
      )}
      {props.category == "utilidades" && (
        <IOSSlider
          {...props}
          marks={marks}
          aria-label="Volume"
          orientation="horizontal"
          valueLabelDisplay="auto"
        />
      )}
    </>
  );
};

export default SliderSami;
