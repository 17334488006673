import Grid from "@mui/material/Grid";

import TarjetaConOdometro from "./TarjetaConOdometro";


import TarjetaSlider from "./TarjetaSlider.jsx";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GeneradoresAcordeon from './GeneradoresAcordeon';
import GeneradoresCard from './GeneradoresCard';
import ElementosDeLista from './ElementosDeLista'
import Box from '@mui/material/Box';
import List from '@mui/material/List';


import img1 from '../img/1.png'
import img2 from '../img/2.png'
import img3 from '../img/3.png'
import img8 from '../img/8.png'
import img15 from '../img/15.png'
import img20 from '../img/20.png'
import img29 from '../img/29.png'
import img39 from '../img/39.png'
import img40 from '../img/40.png'
import img41 from '../img/41.png'
import img42 from '../img/42.png'
import img44 from '../img/44.png'
import img45 from '../img/45.png'
import img46 from '../img/46.png'
import img48 from '../img/48.png'
import img50 from '../img/50.png'
import img51 from '../img/51.png'
import img52 from '../img/52.png'
import img53 from '../img/53.png'
import img56 from '../img/56.png'
import img60 from '../img/60.png'
import img64 from '../img/64.png'
import img77 from '../img/77.png'
import img79 from '../img/79.png'
import img80 from '../img/80.png'
import imgMejorarMarketing from '../img/24.png'
import imgMarketingTradicional from '../img/65.png'
import diamante from '../img/diamante3c.png'
import imgBasico from '../img/17.png'
import imgListasProspectos from '../img/72.png'
import imgInternetLinea from '../img/66.png'




export const TableroResultados = ({ theme }) => {


  return (
    <>
      {/* Marketing */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={diamante} width="20px" />
            <Typography ml={2}> Marketing</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={img53} width="20px" />
                <Typography ml={2}> Meta</Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Grid
                container
                spacing={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <TarjetaSlider
                  llevaSlider
                  titulo="Inversión en Marketing"
                  campoIndicador1="IM1"
                  campoIndicador2="IM2"
                  campoIndicador3="IMR"
                  campoIncremento="IM_INC"
                  nombreTarjeta="IM"
                  tipoSlider="marketing"
                  adorno="$"
                />

                <TarjetaSlider
                  llevaSlider
                  titulo="Prospectos"
                  campoIndicador1="P1"
                  campoIndicador2="P2"
                  campoIncremento="P_INC"
                  campoIndicador3="PR"
                  nombreTarjeta="P"
                  tipoSlider="marketing"
                />

                <TarjetaSlider
                  llevaSlider
                  titulo="Tasa de conversión"
                  campoIndicador1="TC1"
                  campoIndicador2="TC2"
                  campoIncremento="TC_INC"
                  campoIndicador3="TCR"
                  nombreTarjeta="TC"
                  tipoSlider="marketing"
                  adorno="%"
                />
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={img8} width="20px" />
                <Typography ml={2}>Conseguir más prospectos</Typography>
              </Box>
            </AccordionSummary>


            <AccordionDetails>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={imgBasico} width="20px" />
                    <Typography ml={2}> Lo básico</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} sx={{ display: "flex", justifyContent: "start" }} >

                    <GeneradoresCard img={imgBasico} titulo="Control básico del marketing" azul
                      numeros={[181, 182, 183, 184, 185]} >
                      <ElementosDeLista id={181} texto="Meta de generación de prospectos" />
                      <ElementosDeLista id={182} texto="Medir la cantidad de prospectos generados" />
                      <ElementosDeLista id={183} texto="Rentabilidad de las campañas de marketing" />
                      <ElementosDeLista id={184} texto="Meta de costo de adquisición de cliente" />
                      <ElementosDeLista id={185} texto="Reactivar clientes anteriores" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgBasico} titulo="Socios, alianzas, distribuidores" azul
                      numeros={[186, 187, 188, 189, 190]} >
                      <ElementosDeLista id={186} texto="Alianzas estratégicas" />
                      <ElementosDeLista id={187} texto="Alianzas anfitrión beneficiario" />
                      <ElementosDeLista id={188} texto="Socios" />
                      <ElementosDeLista id={189} texto="Distribuidores" />
                      <ElementosDeLista id={190} texto="Nuevos territorios" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgBasico} titulo="Referidos" azul grande
                      numeros={[212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222]} >
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={212} texto="Evento para clientes anteriores" />
                            <ElementosDeLista id={213} texto="Que tu cliente dé a sus amigos y/o familiares un regalo de tu parte" />
                            <ElementosDeLista id={214} texto="Fiesta para celebrar la compra de tu cliente" />
                            <ElementosDeLista id={215} texto="Atrévete: pídele referidos a la gente que te compró" />
                            <ElementosDeLista id={216} texto="Mantén contacto regular con tus clientes anteriores y actuales" />
                            <ElementosDeLista id={217} texto="Tarjetas VIP" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={218} texto="Certificados de regalo" />
                            <ElementosDeLista id={219} texto="Productos / servicios gratis" />
                            <ElementosDeLista id={220} texto="Comisiones por referidos" />
                            <ElementosDeLista id={221} texto="Regalos (sencillos o de alto valor percibido)" />
                            <ElementosDeLista id={222} texto="Trae a un amigo para acceder a una oferta" />
                          </List>
                        </Grid>
                      </Grid>
                      <Box sx={{ height: "12px" }}></Box>
                    </GeneradoresCard>

                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={imgMarketingTradicional} width="20px" />
                    <Typography ml={2}> Marketing tradicional</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} sx={{ display: "flex", justifyContent: "start" }} >
                    <GeneradoresCard img={imgMarketingTradicional} titulo="Uso de medios publicitarios impresos" azul
                      numeros={[267, 268, 269, 270, 271, 272, 273, 274, 275, 276]} >
                      <ElementosDeLista id={267} texto="Taxis" />
                      <ElementosDeLista id={268} texto="Camiones" />
                      <ElementosDeLista id={269} texto="Trenes" />
                      <ElementosDeLista id={270} texto="Folletos o cartas por correo" />
                      <ElementosDeLista id={271} texto="Cartas con truco" />
                      <ElementosDeLista id={272} texto="En boletos" />
                      <ElementosDeLista id={273} texto="En recibos" />
                      <ElementosDeLista id={274} texto="En facturas" />
                      <ElementosDeLista id={275} texto="En empaques" />
                      <ElementosDeLista id={276} texto="Tarjeta presentación espectacular" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgMarketingTradicional} titulo="Publicidad en medios masivos" azul grande
                      numeros={[285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301]}>
                      <Box sx={{ height: "20px" }}></Box>
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={285} texto="Radio" />
                            <ElementosDeLista id={286} texto="Televisión" />
                            <ElementosDeLista id={287} texto="Cines" />
                            <ElementosDeLista id={288} texto="Teatros" />
                            <ElementosDeLista id={289} texto="Medios masivos" />
                            <ElementosDeLista id={290} texto="Periódicos" />
                            <ElementosDeLista id={291} texto="Inserciones" />
                            <ElementosDeLista id={292} texto="Boletines" />
                            <ElementosDeLista id={293} texto="Revistas" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={294} texto="Volantes en lugares estratégicos" />
                            <ElementosDeLista id={295} texto="Folletos" />
                            <ElementosDeLista id={296} texto="Catálogos" />
                            <ElementosDeLista id={297} texto="Tarjetas" />
                            <ElementosDeLista id={298} texto="Camiones" />
                            <ElementosDeLista id={299} texto="Taxis" />
                            <ElementosDeLista id={300} texto="Autos" />
                            <ElementosDeLista id={301} texto="Aviones" />
                          </List>
                        </Grid>
                      </Grid>
                      <Box sx={{ height: "8px" }}></Box>
                    </GeneradoresCard>

                    <GeneradoresCard img={imgMarketingTradicional} titulo="Publicidad en lugares públicos" azul grande
                      numeros={[277, 278, 279, 280, 281, 282, 283, 284]}>
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={277} texto="Lugares transcurridos" />
                            <ElementosDeLista id={278} texto="Centros comerciales" />
                            <ElementosDeLista id={279} texto="Zonas transitadas" />
                            <ElementosDeLista id={280} texto="Zonas exclusivas" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={281} texto="Lonas" />
                            <ElementosDeLista id={282} texto="Posters" />
                            <ElementosDeLista id={283} texto="Aparadores" />
                            <ElementosDeLista id={284} texto="Exhibidores" />
                          </List>
                        </Grid>
                      </Grid>
                    </GeneradoresCard>


                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={imgMejorarMarketing} width="20px" />
                    <Typography ml={2}> Mejorar el marketing que ya hacemos</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} sx={{ display: "flex", justifyContent: "start" }} >
                    <GeneradoresCard img={imgMejorarMarketing} titulo="Mejorar el marketing que ya hacemos" azul
                      numeros={[191, 192, 193, 194, 195]} >
                      <ElementosDeLista id={191} texto="Mejora los mensajes y el coping" />
                      <ElementosDeLista id={192} texto="Mejora la imagen y apariencia del anuncio" />
                      <ElementosDeLista id={193} texto="Uso de la técnica AIDA" />
                      <ElementosDeLista id={194} texto="Cambia el medio publicitario" />
                      <ElementosDeLista id={195} texto="Cambia de sección/horario en el mismo medio" />
                    </GeneradoresCard>


                    <GeneradoresCard img={imgMejorarMarketing} titulo="Usar avatars e identificar características comunes" azul
                      numeros={[196, 197, 198, 199, 200, 201, 202]} >
                      <ElementosDeLista id={196} texto="Estudiar a los clientes que ya tenemos" />
                      <ElementosDeLista id={197} texto="Estudiar a los clientes que queremos tener" />
                      <ElementosDeLista id={198} texto="¿Qué leen?" />
                      <ElementosDeLista id={199} texto="Tipo de marketing que consumen" />
                      <ElementosDeLista id={200} texto="Lugares que visitan" />
                      <ElementosDeLista id={201} texto="Deportes que les gustan" />
                      <ElementosDeLista id={202} texto="Religión, costumbres, etc" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgMejorarMarketing} titulo="Usar técnicas de neurociencias" azul grande
                      numeros={[203, 204, 205, 206, 207, 208, 209, 210, 211]} >
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={203} texto="Uso de emociones en el proceso de ventas" />
                            <ElementosDeLista id={204} texto="Uso de avatar-producto-propuesta de valor" />
                            <ElementosDeLista id={205} texto="Uso de botones reptilianos" />
                            <ElementosDeLista id={206} texto="Uso de arquetipos" />
                            <ElementosDeLista id={207} texto="Uso de metáforas" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={208} texto="Uso de historias (StoryTelling)" />
                            <ElementosDeLista id={209} texto="Uso de código simbólico" />
                            <ElementosDeLista id={210} texto="Uso de neurosegmentación" />
                            <ElementosDeLista id={211} texto="Uso de técnicas para despertar al reptil" />
                          </List>
                        </Grid>
                      </Grid>
                      <Box sx={{ height: "28px" }}></Box>

                    </GeneradoresCard>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={imgListasProspectos} width="20px" />
                    <Typography ml={2}> Listas de prospectos</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} sx={{ display: "flex", justifyContent: "start" }} >


                    <GeneradoresCard img={imgListasProspectos} titulo="Conseguir una lista de prospectos" azul
                      numeros={[261, 262, 263, 264, 265, 266]} >
                      <ElementosDeLista id={261} texto="Directorio empresarial" />
                      <ElementosDeLista id={262} texto="Encuentren.me" />
                      <ElementosDeLista id={263} texto="Directorioempresas.mx" />
                      <ElementosDeLista id={264} texto="LinkedIn" />
                      <ElementosDeLista id={265} texto="Intercambio de bases de datos" />
                      <ElementosDeLista id={266} texto="Cámaras" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgListasProspectos} titulo="Crear lista de prospectos con datos de gobierno" azul
                      numeros={[223, 224, 225, 226, 227, 228]} >
                      <ElementosDeLista id={223} texto="Formar una lista con datos del INEGI" />
                      <ElementosDeLista id={224} texto="Formar una lista con datos del Denue" />
                      <ElementosDeLista id={225} texto="Formar una lista con datos del gaceta oficial" />
                      <ElementosDeLista id={226} texto="Formar una lista con datos de licitaciones" />
                      <ElementosDeLista id={227} texto="Formar una lista con datos de juicios" />
                      <ElementosDeLista id={228} texto="Formar una lista con padrón de proveedores" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgListasProspectos} titulo="Crear lista de prospectos haciendo networking, relaciones y otros"
                      azul numeros={[239, 240, 241, 242, 243, 244, 245, 246]}>
                      <ElementosDeLista id={239} texto="Relaciones públicas" />
                      <ElementosDeLista id={240} texto="Clústeres" />
                      <ElementosDeLista id={241} texto="Intercambio de tarjetas" />
                      <ElementosDeLista id={242} texto="Networking" />
                      <ElementosDeLista id={243} texto="Coworking" />
                      <ElementosDeLista id={244} texto="Avante" />
                      <ElementosDeLista id={245} texto="BNI" />
                      <ElementosDeLista id={246} texto="Noches Tec" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgListasProspectos} titulo="Crear lista de prospectos usando búsquedas" azul grande
                      numeros={[229, 230, 231, 232, 233, 234, 235, 236, 237, 238]} >
                      <Box sx={{ height: "20px" }}></Box>
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={229} texto="Directorio de empresas especializadas" />
                            <ElementosDeLista id={230} texto="Prospectos por búsqueda en internet" />
                            <ElementosDeLista id={231} texto="Prospectos por registros de marca" />
                            <ElementosDeLista id={232} texto="Prospectos por listas de fabricantes" />
                            <ElementosDeLista id={233} texto="Prospectos por escuelas" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={234} texto="Prospectos por listas de distribuidores" />
                            <ElementosDeLista id={235} texto="Prospectos por sección blanca" />
                            <ElementosDeLista id={236} texto="Prospectos por sección amarilla o similares" />
                            <ElementosDeLista id={237} texto="Prospectos por publicaciones locales" />
                            <ElementosDeLista id={238} texto="Buscando en la sección amarilla" />
                          </List>
                        </Grid>
                      </Grid>
                      <Box sx={{ height: "32px" }}></Box>
                    </GeneradoresCard>

                    <GeneradoresCard img={imgListasProspectos} titulo="Crear lista de prospectos con actividades de prospección" azul
                      grande numeros={[247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260]}>
                      <Box sx={{ height: "22px" }}></Box>
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={247} texto="Llamada en frío para conseguir datos" />
                            <ElementosDeLista id={248} texto="Cambaceo" />
                            <ElementosDeLista id={249} texto="Expos" />
                            <ElementosDeLista id={250} texto="Curso pagado o gratuito" />
                            <ElementosDeLista id={251} texto="Actualización" />
                            <ElementosDeLista id={252} texto="Podcast" />
                            <ElementosDeLista id={253} texto="Espectáculos" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={254} texto="Fiestas" />
                            <ElementosDeLista id={255} texto="Inauguraciones" />
                            <ElementosDeLista id={256} texto="Presentación" />
                            <ElementosDeLista id={257} texto="Open house" />
                            <ElementosDeLista id={258} texto="Evento clientes anteriores" />
                            <ElementosDeLista id={259} texto="Conferencia" />
                            <ElementosDeLista id={260} texto="Webinar" />
                          </List>
                        </Grid>
                      </Grid>
                      <Box sx={{ height: "30px" }}></Box>
                    </GeneradoresCard>

                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={imgInternetLinea} width="20px" />
                    <Typography ml={2}>Internet y en línea</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} sx={{ display: "flex", justifyContent: "start" }} >

                    <GeneradoresCard img={imgInternetLinea} titulo="Anuncios pagados en internet" azul
                      numeros={[302, 303, 304, 305]} >
                      <ElementosDeLista id={302} texto="Google ads" />
                      <ElementosDeLista id={303} texto="Youtube ads" />
                      <ElementosDeLista id={304} texto="Facebook ads" />
                      <ElementosDeLista id={305} texto="Anuncios en apps" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgInternetLinea} titulo="Estrategia de chatMarketing" azul
                      numeros={[306, 307, 308, 309]} >
                      <ElementosDeLista id={306} texto="WhatsApp" />
                      <ElementosDeLista id={307} texto="SMS" />
                      <ElementosDeLista id={308} texto="Inbox" />
                      <ElementosDeLista id={309} texto="Bots" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgInternetLinea} titulo="Redes sociales" azul
                      numeros={[316, 317, 318, 319, 320, 321, 322]}>
                      <ElementosDeLista id={316} texto="Anuncio pagado LinkedIn" />
                      <ElementosDeLista id={317} texto="Anuncio pagado facebook" />
                      <ElementosDeLista id={318} texto="Anuncio pagado instagram" />
                      <ElementosDeLista id={319} texto="Eventos de redes sociales" />
                      <ElementosDeLista id={320} texto="Generics y páginas genéricas incluso de terceros" />
                      <ElementosDeLista id={321} texto="Fan Page" />
                      <ElementosDeLista id={322} texto="Grupos" />
                    </GeneradoresCard>

                    <GeneradoresCard img={imgInternetLinea} titulo="Estrategia de remarketing" azul grande
                      numeros={[310, 311, 312, 313, 314, 315, 323, 333]}>
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={310} texto="Pixeles de seguimiento" />
                            <ElementosDeLista id={311} texto="Con base en pixel de seguimiento" />
                            <ElementosDeLista id={312} texto="Por colonia y ubicación" />
                            <ElementosDeLista id={313} texto="Subir base de datos propia" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={314} texto="Segmentación y neurosegmentación" />
                            <ElementosDeLista id={315} texto="Simulación participación expo" />
                            <ElementosDeLista id={323} texto="Sitios especializados (trivago, expidia, tripadvasor,etc)" />
                            <ElementosDeLista id={333} texto="Estrategia funels y email marketing" />
                          </List>
                        </Grid>
                      </Grid>
                    </GeneradoresCard>



                    <GeneradoresCard img={imgInternetLinea} titulo="Estrategia de venta en línea" azul grande
                      numeros={[331, 332, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344]} >
                      <Grid container spacing={1} columns={2}>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={331} texto="Páginas web propias" />
                            <ElementosDeLista id={332} texto="Landing pages" />
                            <ElementosDeLista id={334} texto="WhatsApp manual" />
                            <ElementosDeLista id={335} texto="WhatsApp integrado a CRM" />
                            <ElementosDeLista id={336} texto="WhatsApp automático con bots" />
                            <ElementosDeLista id={337} texto="Rapid" />
                            <ElementosDeLista id={338} texto="Uber Eats" />
                          </List>
                        </Grid>
                        <Grid item sm={2} md={1}>
                          <List dense disablePadding>
                            <ElementosDeLista id={339} texto="Didi" />
                            <ElementosDeLista id={340} texto="Apps de pedidos y ventas propia" />
                            <ElementosDeLista id={341} texto="Amazon" />
                            <ElementosDeLista id={342} texto="Mercado libre" />
                            <ElementosDeLista id={343} texto="Expo tu boda" />
                            <ElementosDeLista id={344} texto="Tienda virtual propia" />
                          </List>
                        </Grid>
                      </Grid>
                    </GeneradoresCard>

                    <GeneradoresCard img={imgInternetLinea} titulo="Inbound marketing" azul
                      numeros={[324, 325, 326, 327, 328, 329, 330]}>
                      <ElementosDeLista id={324} texto="Blogs" />
                      <ElementosDeLista id={325} texto="Videos" />
                      <ElementosDeLista id={326} texto="Consejos" />
                      <ElementosDeLista id={327} texto="Información gratis" />
                      <ElementosDeLista id={328} texto="Revista online" />
                      <ElementosDeLista id={329} texto="Boletín online" />
                      <ElementosDeLista id={330} texto="NewsLetter online" />
                    </GeneradoresCard>


                  </Grid>
                </AccordionDetails>
              </Accordion>




            </AccordionDetails>


          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={img20} width="20px" />
                <Typography ml={2}>Mejorar la tasa de conversión</Typography>
              </Box>
            </AccordionSummary>


            <AccordionDetails>


              <GeneradoresAcordeon titulo="Lo básico" img={img20}>
                <GeneradoresCard img={img20} titulo="Control básico de la tasa de conversión" azul numeros={[127, 128]}>
                  <br />
                  <ElementosDeLista id={127} texto="Mide la tasa de conversión prospecto a cliente" />
                  <ElementosDeLista id={128} texto="Acciones para mejorar la tasa de conversión" />
                  <Box sx={{ height: "32px" }}></Box>
                </GeneradoresCard>

                <GeneradoresCard img={img79} titulo="Propuesta de valor" azul numeros={[129, 130, 131, 132]} >
                  <ElementosDeLista id={129} texto="Mejorar la claridad en nuestra diferenciación" />
                  <ElementosDeLista id={130} texto="Desarrolla o mejora tu propuesta de valor" />
                  <ElementosDeLista id={131} texto="Ofrece garantías avanzadas" />
                  <ElementosDeLista id={132} texto="Establece y comunica en qué eres No. 1" />
                </GeneradoresCard>

                <GeneradoresCard img={img60} titulo="Facilita la compra" azul numeros={[144, 145, 146, 147]}>
                  <ElementosDeLista id={144} texto="Ayuda al cliente a elegir" />
                  <ElementosDeLista id={145} texto="Elimina burocracia y procesos innecesarios" />
                  <ElementosDeLista id={146} texto="Ofrece sesiones y reuniones virtuales" />
                  <ElementosDeLista id={147} texto="Pedidos por internet, whatsapp, etc" />
                </GeneradoresCard>

                <GeneradoresCard img={img60}
                  titulo="Créditos, apartados y facilidades de pago"
                  azul grande numeros={[133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143]}>
                  <Grid container spacing={1} columns={2}>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={133} texto="Crédito y apartados" />
                        <ElementosDeLista id={134} texto="Opciones de pago y otros" />
                        <ElementosDeLista id={135} texto="Planes de prepago" />
                        <ElementosDeLista id={136} texto="Financiamiento con recursos propios" />
                        <ElementosDeLista id={137} texto="Financiamiento con recurso de terceros" />
                        <ElementosDeLista id={138} texto="Pagos a meses sin intereses" />
                      </List>
                    </Grid>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={139} texto="Pagos a meses con intereses" />
                        <ElementosDeLista id={140} texto="Proceso de aprobación de crédito" />
                        <ElementosDeLista id={141} texto="Pago con tarjeta" />
                        <ElementosDeLista id={142} texto="Acepta anticipos" />
                        <ElementosDeLista id={143} texto="Permite apartados" />
                      </List>
                    </Grid>
                  </Grid>
                </GeneradoresCard>
              </GeneradoresAcordeon>



              <GeneradoresAcordeon titulo="Mejora el proceso" img={img77}>
                <GeneradoresCard img={img8} titulo="Que tus clientes se sientan especiales"
                  azul numeros={[162, 163, 164, 165, 166]}>
                  <ElementosDeLista id={162} texto="Técnicas para dar y recibir" />
                  <ElementosDeLista id={163} texto="Regalo de bajo costo alto valor percibido" />
                  <ElementosDeLista id={164} texto="Usa tarjetas de ocasión especial" />
                  <ElementosDeLista id={165} texto="Dar refrigerios" />
                  <ElementosDeLista id={166} texto="Muestras o demostraciones gratis" />
                  <Box sx={{ height: "28px" }}></Box>
                </GeneradoresCard>

                <GeneradoresCard img={img8} titulo="Mejora el proceso de ventas" azul grande
                  numeros={[148, 149, 150, 151, 152, 153, 154, 180]} >
                  <Box sx={{ height: "10px" }}></Box>
                  <Grid container spacing={1} columns={2}>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={148} texto="Vuélvete autoridad en el tema" />
                        <ElementosDeLista id={149} texto="Escribe un libro para tus clientes" />
                        <ElementosDeLista id={150} texto="Muestra tus blogs" />
                        <ElementosDeLista id={151} texto="Muestra tus reconocimientos" />
                      </List>
                    </Grid>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={152} texto="Muestra tus certificaciones" />
                        <ElementosDeLista id={153} texto="Muestra los premios ganados" />
                        <ElementosDeLista id={154} texto="Crea una marca personal" />
                        <ElementosDeLista id={180} texto="Proceso especializado para B2B y/o B2C" />
                      </List>
                    </Grid>
                  </Grid>
                  <Box sx={{ height: "18px" }}></Box>
                </GeneradoresCard>

                <GeneradoresCard img={img8} titulo="Confianza y empatía" azul numeros={[167, 168, 169, 170, 171]} >
                  <ElementosDeLista id={167} texto="Contar tu historia (HistoryTelling)" />
                  <ElementosDeLista id={168} texto="Contar tu visión (HistoryTelling)" />
                  <ElementosDeLista id={169} texto="Sé tan honesto que no puedan resistirse" />
                  <ElementosDeLista id={170} texto="Proporciona información adecuada" />
                  <ElementosDeLista id={171} texto="Educa a tu cliente antes de que te visite" />
                </GeneradoresCard>

                <GeneradoresCard img={img8} titulo="Usa material de apoyo adecuado" azul grande
                  numeros={[157, 158, 159, 160, 161, 155, 156]} >
                  <Grid container spacing={1} columns={2}>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={157} texto="Guión e imágenes adecuadas para whatsapp" />
                        <ElementosDeLista id={158} texto="Uso muestras" />
                        <ElementosDeLista id={159} texto="Fotos de ejemplo" />
                        <ElementosDeLista id={160} texto="Testimonios" />
                      </List>
                    </Grid>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={161} texto="Ten catálogos e información en línea" />
                        <ElementosDeLista id={155} texto="Vestimenta y uniformes" />
                        <ElementosDeLista id={156} texto="Mejora la apariencia del producto" />
                      </List>
                    </Grid>
                  </Grid>
                </GeneradoresCard>

                <GeneradoresCard img={img8} titulo="Capacitación de técticas de ventas" azul grande
                  numeros={[172, 173, 174, 175, 176, 177, 178, 179]} >
                  <Grid container spacing={1} columns={2}>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={172} texto="Capacita en técnicas de ventas modernas" />
                        <ElementosDeLista id={173} texto="Capacita en el uso de pre-cierres" />
                        <ElementosDeLista id={174} texto="Capacita en el uso de acuerdos previos" />
                        <ElementosDeLista id={175} texto="Capacita en el uso de reversiones" />
                      </List>
                    </Grid>
                    <Grid item sm={2} md={1}>
                      <List dense disablePadding>
                        <ElementosDeLista id={176} texto="Capacita en el manejo de objeciones" />
                        <ElementosDeLista id={177} texto="Capacita a tu equipo en neuroventas" />
                        <ElementosDeLista id={178} texto="Capacita en el uso de DISC en ventas" />
                        <ElementosDeLista id={179} texto="Capacita en el uso de tipos de inteligencia" />
                      </List>
                    </Grid>
                  </Grid>
                </GeneradoresCard>

              </GeneradoresAcordeon>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={img2} width="20px" />
                <Typography ml={2}> Resultados</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <TarjetaConOdometro
                  titulo="Clientes Nuevos"
                  campoIndicador1="CN1"
                  campoIndicador2="CN2"
                  campoIndicador3="CNR"
                  campoIncremento="CN_INC"
                  category="marketing"
                  tipoIndicador={1}
                  nombreTarjeta="CN"
                  triple
                />

                <TarjetaConOdometro
                  titulo="Costo por prospecto"
                  campoIndicador1="CPP1"
                  campoIndicador2="CPP2"
                  campoIncremento="CPP_INC"
                  category="marketing"
                  adorno="$"
                  tipoIndicador={7}
                  nombreTarjeta="CPP"
                />

                <TarjetaConOdometro
                  titulo="Costo por cliente"
                  campoIndicador1="CPC1"
                  campoIndicador2="CPC2"
                  campoIncremento="CPC_INC"
                  category="marketing"
                  adorno="$"
                  tipoIndicador={7}
                  nombreTarjeta="CPC"
                />

              </Grid>
            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

      {/* Ventas */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={diamante} width="20px" />
            <Typography ml={2}> Ventas</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>


          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={img53} width="20px" />
                <Typography ml={2}> Meta</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <TarjetaSlider
                  llevaSlider
                  titulo="Clientes Anteriores"
                  campoIndicador1="CA1"
                  campoIndicador2="CA2"
                  campoIncremento="CA_INC"
                  campoIndicador3="CAR"
                  nombreTarjeta="CA"
                  tipoSlider="ventas"
                />

                <TarjetaSlider
                  llevaSlider
                  titulo="Ticket Promedio"
                  campoIndicador1="CP1"
                  campoIndicador2="CP2"
                  campoIncremento="CP_INC"
                  campoIndicador3="CPR"
                  nombreTarjeta="CP"
                  tipoSlider="ventas"
                  adorno="$"
                />
                <TarjetaSlider
                  llevaSlider
                  titulo="Frecuencia de compra"
                  campoIndicador1="FC1"
                  campoIndicador2="FC2"
                  campoIncremento="FC_INC"
                  campoIndicador3="FCR"
                  nombreTarjeta="FC"
                  tipoSlider="ventas"
                />

              </Grid>
            </AccordionDetails>
          </Accordion>

          <GeneradoresAcordeon titulo="Mantener a los buenos clientes" img={img41}>

            <GeneradoresCard img={img77} titulo="Cumplir la promesa de ventas" verde grande
              numeros={[1, 2, 3, 4, 5, 6, 7, 8]}>
              <Grid container spacing={1} columns={2}>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={1} texto="Mide la satisfacción del cliente" />
                    <ElementosDeLista id={2} texto="Ten una meta de tiempo de vida del cliente" />
                    <ElementosDeLista id={3} texto="Seguimiento al proceso promesa de ventas" />
                    <ElementosDeLista id={4} texto="Promete menos, entrega más" />
                  </List>
                </Grid>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={5} texto="Sorprende para bien a tu cliente" />
                    <ElementosDeLista id={6} texto="Detecta qué haces bien" />
                    <ElementosDeLista id={7} texto="Detecta por qué dejaron de comprar" />
                    <ElementosDeLista id={8} texto="Mide la efectividad de la propuesta de valor" />
                  </List>
                </Grid>
              </Grid>
            </GeneradoresCard>

            <GeneradoresCard img={img15} titulo="Seguimiento y relación" verde
              numeros={[9, 10]} >
              <ElementosDeLista id={9} texto="Mantén el contacto" />
              <ElementosDeLista id={10} texto="Dar seguimiento de acuerdo al proceso" />
              <br /><br />
            </GeneradoresCard>

          </GeneradoresAcordeon>

          <GeneradoresAcordeon titulo="Aumentar la compra por cliente" img={img46}>

            <GeneradoresCard img={img2} titulo="Proceso para aumenta compra promedio" verde
              numeros={[86, 96, 97, 98, 126]}>
              <ElementosDeLista id={86} texto="Define un proceso de varios toques" />
              <ElementosDeLista id={96} texto="Entrena tus vendedores en el proceso" />
              <ElementosDeLista id={97} texto="Usa testimonios" />
              <ElementosDeLista id={98} texto="Diseñar y usar material de apoyo adecuado" />
              <ElementosDeLista id={126} texto="Crea un proceso para detectar nuevas necesidades" />
            </GeneradoresCard>

            <GeneradoresCard img={img2} titulo="Ofrece productos o servicios adicionales" verde
              numeros={[92, 93, 94, 95]} >
              <ElementosDeLista id={92} texto="Listas de verificación" />
              <ElementosDeLista id={93} texto="Cruce de venta" />
              <ElementosDeLista id={94} texto="Venta descendente" />
              <ElementosDeLista id={95} texto="Venta ascendente" />
              <br />
            </GeneradoresCard>

            <GeneradoresCard img={img1} titulo="Control básico de la compra por cliente" verde
              numeros={[108, 109]} >
              <br />
              <ElementosDeLista id={108} texto="Establece una meta de compra promedio" />
              <ElementosDeLista id={109} texto="Mide la compra promedio" />
              <br />
              <Box sx={{ height: "8px" }}></Box>
            </GeneradoresCard>
          </GeneradoresAcordeon>

          <GeneradoresAcordeon titulo="Aumentar la frecuencia de compra" img={img40}>
            <GeneradoresCard img={img1} titulo="Control básico de la frecuencia de compra" verde
              numeros={[110, 300]} >
              <br />
              <ElementosDeLista id={300} texto="Meta de frecuencia de compra promedio" />
              <ElementosDeLista id={110} texto="Mide la frecuencia de compra promedio" />
              <br />
              <Box sx={{ height: "8px" }}></Box>
            </GeneradoresCard>

            <GeneradoresCard img={img15} titulo="Programa cliente frecuente" verde
              numeros={[122, 123, 124, 125]} >
              <br />
              <ElementosDeLista id={122} texto="Proceso compra repetible" />
              <ElementosDeLista id={123} texto="Beneficios VIP" />
              <ElementosDeLista id={124} texto="Tarjeta de puntos" />
              <ElementosDeLista id={125} texto="Ofertas especiales" />
              <Box sx={{ height: "32px" }}></Box>
            </GeneradoresCard>
          </GeneradoresAcordeon>

          <GeneradoresAcordeon titulo="Ofertas y mejoras" img={img52}>
            <GeneradoresCard img={img2} titulo="Define las preguntas clave del guión" verde
              numeros={[87, 88, 89, 90, 91]}>
              <ElementosDeLista id={87} texto="Cómo vas a saludar a tu cliente" />
              <ElementosDeLista id={88} texto="Preguntas abiertas" />
              <ElementosDeLista id={89} texto="Preguntas cerradas" />
              <ElementosDeLista id={90} texto="Cómo se presenta el producto" />
              <ElementosDeLista id={91} texto="Cómo y cuándo medir la temperatura de cierre" />
            </GeneradoresCard>

            <GeneradoresCard img={img2} titulo="Incorporar conocimientos de neurociencia" verde grande
              numeros={[99, 100, 101, 102, 103, 104, 1105, 106, 107]}>
              <Grid container spacing={1} columns={2}>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={99} texto="Incorporar el uso de tipos de inteligencia" />
                    <ElementosDeLista id={100} texto="Detectar frustraciones y dolores" />
                    <ElementosDeLista id={101} texto="Perfiles DISC en el proceso de ventas" />
                    <ElementosDeLista id={102} texto="Venta con base en emociones" />
                    <ElementosDeLista id={103} texto="Uso de botones reptilianos" />
                  </List>
                </Grid>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={104} texto="Uso de arquetipos" />
                    <ElementosDeLista id={105} texto="Uso de códigos simbólicos" />
                    <ElementosDeLista id={106} texto="Uso de metáforas" />
                    <ElementosDeLista id={107} texto="Uso de historias" />
                  </List>
                </Grid>
              </Grid>
            </GeneradoresCard>

            <GeneradoresCard img={img52} titulo="Ofertas" verde grande
              numeros={[111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121]} >
              <Grid container spacing={1} columns={2}>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={111} texto="Oferta bajo costo alto valor percibido" />
                    <ElementosDeLista id={112} texto="Oferta por compras en volumen" />
                    <ElementosDeLista id={113} texto="Ofertas regulares" />
                    <ElementosDeLista id={114} texto="El producto de la semana" />
                    <ElementosDeLista id={115} texto="Compra uno llévate otro gratis" />
                    <ElementosDeLista id={116} texto="Ofertas especiales" />
                  </List>
                </Grid>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={117} texto="Regalo por compra de x pesos" />
                    <ElementosDeLista id={118} texto="Ofertas de 4x3, 2x1, 3x2, etc" />
                    <ElementosDeLista id={119} texto="Oferta en certificado regalo" />
                    <ElementosDeLista id={120} texto="Oferta sobre la siguiente compra" />
                    <ElementosDeLista id={121} texto="Oferta con cupón de descuento" />
                  </List>
                </Grid>
              </Grid>
            </GeneradoresCard>
          </GeneradoresAcordeon>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={img2} width="20px" />
                <Typography ml={2}> Resultados</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >

                <TarjetaConOdometro
                  titulo="Clientes Totales  "
                  campoIndicador1="CT1"
                  campoIndicador2="CT2"
                  campoIncremento="CT_INC"
                  mostrarBotonDetalles={false}
                  category="ventas"
                  tipoIndicador={4}
                  nombreTarjeta="CT"
                />
                <TarjetaConOdometro
                  titulo="Ventas totales"
                  campoIndicador1="VT1"
                  campoIndicador2="VT2"
                  campoIncremento="VT_INC"
                  mostrarBotonDetalles={false}
                  category="ventas"
                  adorno="$"
                  tipoIndicador={5}
                  nombreTarjeta="VT"
                />
              </Grid>
            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

      {/* Utilidades */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={diamante} width="20px" />
            <Typography ml={2}> Utilidades</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={img53} width="20px" />
                <Typography ml={2}> Meta</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <TarjetaSlider
                  llevaSlider
                  titulo="Margen Bruto"
                  campoIndicador1="MB1"
                  campoIndicador2="MB2"
                  campoIncremento="MB_INC"
                  campoIndicador3="MBR"
                  nombreTarjeta="MB"
                  tipoSlider="utilidades"
                  adorno="%"
                />
                <TarjetaSlider
                  llevaSlider
                  titulo="Gastos Fijos"
                  campoIndicador1="GF1"
                  campoIndicador2="GF2"
                  campoIncremento="GF_INC"
                  campoIndicador3="GFR"
                  nombreTarjeta="GF"
                  tipoSlider="utilidades"
                  adorno="$"
                />
                <TarjetaSlider
                  llevaSlider
                  titulo="Impuestos"
                  campoIndicador1="IMP1"
                  campoIndicador2="IMP2"
                  campoIndicador3="IMPR"
                  campoIncremento="IMP_INC"
                  nombreTarjeta="IMP"
                  tipoSlider="utilidades"
                  adorno="$"
                />
              </Grid>
            </AccordionDetails>
          </Accordion>

          <GeneradoresAcordeon titulo="Disminuir gastos" img={img39}>

            <GeneradoresCard img={img3} titulo="Control básico de gastos fijos" rojo
              numeros={[11, 12, 13, 14]} >
              <ElementosDeLista id={11} texto="Conocer los números del negocio" />
              <ElementosDeLista id={12} texto="Separa los gastos personales de los del negocio" />
              <ElementosDeLista id={13} texto="Asignar un sueldo a los dueños cuando son empleados" />
              <ElementosDeLista id={14} texto="Control de retiro de utilidades y reinversión" />
            </GeneradoresCard>

            <GeneradoresCard img={img39} titulo="Reducción de gastos" rojo
              numeros={[15, 16, 17, 18, 19]} >
              <ElementosDeLista id={15} texto="Campaña de reducción de gastos" />
              <ElementosDeLista id={16} texto="Mejora en procesos para evitar retrabajo" />
              <ElementosDeLista id={17} texto="Asesoría fiscal externa" />
              <ElementosDeLista id={18} texto="Servicios de outsourcing (legales)" />
              <ElementosDeLista id={19} texto="Servicios de pago de nóminas externo" />
            </GeneradoresCard>

          </GeneradoresAcordeon>

          <GeneradoresAcordeon titulo="Disminuir costos" img={img39}>
            <GeneradoresCard img={img39} titulo="Reducción de costos" rojo grande
              numeros={[23, 24, 25, 26, 27, 28, 29]}>
              <Grid container spacing={1} columns={2}>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={23} texto="Reducción de personal innecesario" />
                    <ElementosDeLista id={24} texto="Disminución de mermas y desperdicios" />
                    <ElementosDeLista id={25} texto="Eficientizar y disminuir gastos de ventas" />
                    <ElementosDeLista id={26} texto="Eficientizar y disminuir gastos de marketing" />
                  </List>
                </Grid>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={27} texto="Revisión de procesos y horarios" />
                    <ElementosDeLista id={28} texto="Estudio de tiempos y movimientos" />
                    <ElementosDeLista id={29} texto="Manufacturar tú mismo" />
                  </List>
                </Grid>
              </Grid>
            </GeneradoresCard>

            <GeneradoresCard img={img46} titulo="Empleados y comisiones" rojo grande
              numeros={[50, 51, 52, 53, 54, 55, 56, 57, 58]} >
              <Grid container spacing={1} columns={2}>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={50} texto="Meta y medición de rentabilidad por empleado" />
                    <ElementosDeLista id={51} texto="Usa KPI's por empleado" />
                    <ElementosDeLista id={52} texto="Contrata gente más competente" />
                    <ElementosDeLista id={53} texto="Mejora tu proceso de reclutamiento" />
                    <ElementosDeLista id={54} texto="Mejora tu proceso de capacitación" />
                  </List>
                </Grid>
                <Grid item sm={2} md={1}>
                  <List dense disablePadding>
                    <ElementosDeLista id={55} texto="Usa perfiles DISC" />
                    <ElementosDeLista id={56} texto="Mejora la productividad por empleado" />
                    <ElementosDeLista id={57} texto="Comisiones con base en margen en lugar de ventas" />
                    <ElementosDeLista id={58} texto="Paga con base en productividad o resultados" />
                  </List>
                </Grid>
              </Grid>
            </GeneradoresCard>
          </GeneradoresAcordeon>

          <GeneradoresAcordeon titulo="Mejorar el márgen de utilidad" img={img80}>

            <GeneradoresCard img={img64} titulo="Control básico del margen de utilidad" rojo
              numeros={[20, 21, 22]} >
              <ElementosDeLista id={20} texto="Define una meta de márgenes de utilidad y utilidad" />
              <ElementosDeLista id={21} texto="Mide y controla el márgen de tus principales productos" />
              <ElementosDeLista id={22} texto="Mide y controla el márgen de tus principales clientes" />
            </GeneradoresCard>

            <GeneradoresCard img={img48} titulo="Mejora del margen de utilidad" rojo
              numeros={[30, 31, 32, 33]} >
              <ElementosDeLista id={30} texto="Incrementa los precios (5-10%)" />
              <ElementosDeLista id={31} texto="Evita dar descuentos" />
              <ElementosDeLista id={32} texto="Enfoque a productos mayor márgen" />
              <ElementosDeLista id={33} texto="Ganar por financiamiento" />
            </GeneradoresCard>

            <GeneradoresCard img={img29} titulo="Planeación financiera" rojo
              numeros={[34, 35, 36, 37]} >
              <ElementosDeLista id={34} texto="Dar seguimiento al plan financiero" />
              <ElementosDeLista id={35} texto="Generar y revisar estados financieros" />
              <ElementosDeLista id={36} texto="Planear el retiro de las utilidades del negocio" />
              <ElementosDeLista id={37} texto="Capacitación en finanzas para PYME" />
            </GeneradoresCard>

            <GeneradoresCard img={img51} titulo="Capacitación en finanzas" rojo
              numeros={[60, 61, 62, 63]} >
              <ElementosDeLista id={60} texto="Interpretación de estados financieros" />
              <ElementosDeLista id={61} texto="Uso de razones financieras" />
              <ElementosDeLista id={62} texto="Toma de decisiones con base en finanzas" />
              <ElementosDeLista id={63} texto="Uso de flujos de efectivo" />
            </GeneradoresCard>

            <GeneradoresCard img={img45} titulo="Compras más inteligentes" rojo
              numeros={[38, 39, 40, 41, 42, 43]} >
              <ElementosDeLista id={38} texto="Mejora las negociaciones con los proveedores" />
              <ElementosDeLista id={39} texto="Organiza grupos de compra" />
              <ElementosDeLista id={40} texto="Compra por volumen" />
              <ElementosDeLista id={41} texto="Encontrar productos alternos" />
              <ElementosDeLista id={42} texto="Encontrar proveedores alternos" />
              <ElementosDeLista id={43} texto="Mejorar y eficientizar proceso de compra" />
            </GeneradoresCard>

            <GeneradoresCard img={img50} titulo="Enfoque a clientes más rentables" rojo
              numeros={[44, 45, 46, 47, 48, 49]} >
              <ElementosDeLista id={44} texto="Clasifica a los clientes en ABCD" />
              <ElementosDeLista id={45} texto="Enfoque a ventas recurrentes" />
              <ElementosDeLista id={46} texto="Ser mejor más que el barato" />
              <ElementosDeLista id={47} texto="Deshacerte de malos clientes" />
              <ElementosDeLista id={48} texto="Identificar los buenos clientes" />
              <ElementosDeLista id={49} texto="Enfoque en el valor más que en el precio" />
            </GeneradoresCard>
          </GeneradoresAcordeon>

          <GeneradoresAcordeon titulo="Mejorar el flujo de efectivo" img={img40}>

            <GeneradoresCard img={img40} titulo="Control básico del flujo de efectivo" rojo
              numeros={[64, 65, 66, 67]} >
              <ElementosDeLista id={64} texto="Reporte de cuentas por cobrar" />
              <ElementosDeLista id={65} texto="Reporte de flujo de efectivo" />
              <ElementosDeLista id={66} texto="Mantener el control de inventarios óptimo" />
              <ElementosDeLista id={67} texto="Recuperar CxC vencidas" />
            </GeneradoresCard>

            <GeneradoresCard img={img29} titulo="Planeación flujos y cobranza" rojo
              numeros={[68, 69, 70, 71]} >
              <ElementosDeLista id={68} texto="Definir o mejorar el proceso de cobranza" />
              <ElementosDeLista id={69} texto="Incentivos por pronto pago" />
              <ElementosDeLista id={70} texto="Fomentar pronto pago" />
              <ElementosDeLista id={71} texto="Reducir los plazos de crédito" />
            </GeneradoresCard>

            <GeneradoresCard img={img56} titulo="Negociaciones" rojo
              numeros={[72, 73, 74]}>
              <ElementosDeLista id={72} texto="Negociación de pagos a proveedores" />
              <ElementosDeLista id={73} texto="Negociación de deudas vencidas" />
              <ElementosDeLista id={74} texto="Negociar mercancía en consignación" />
              <br />
            </GeneradoresCard>

            <GeneradoresCard img={img42} titulo="Créditos" rojo
              numeros={[76, 77, 78, 79, 80]} >
              <ElementosDeLista id={76} texto="Líneas de crédito revolvente" />
              <ElementosDeLista id={77} texto="Crédito tradicional" />
              <ElementosDeLista id={78} texto="Tarjetas empresariales" />
              <ElementosDeLista id={79} texto="Crédito de socios" />
              <ElementosDeLista id={80} texto="Crédito familiar" />
            </GeneradoresCard>

            <GeneradoresCard img={img44} titulo="Urgencias y esporádicos" rojo
              numeros={[81, 82, 83, 84, 85]} >
              <ElementosDeLista id={81} texto="Enfoque a ventas rápidas" />
              <ElementosDeLista id={82} texto="Renta de espacios muertos" />
              <ElementosDeLista id={83} texto="Remate sobre inventarios" />
              <ElementosDeLista id={84} texto="Venta de equipos obsoletos" />
              <ElementosDeLista id={85} texto="Venta de mercancía obsoleta" />
            </GeneradoresCard>

          </GeneradoresAcordeon>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={img2} width="20px" />
                <Typography ml={2}> Resultados</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={1}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <TarjetaConOdometro
                  titulo="Utilidad Bruta"
                  campoIndicador1="UB1"
                  campoIndicador2="UB2"
                  campoIncremento="UB_INC"
                  category="utilidades"
                  adorno="$"
                  tipoIndicador={6}
                  nombreTarjeta="UB"
                />
                <TarjetaConOdometro
                  titulo="Utilidad antes de impuestos"
                  campoIndicador1="UA1"
                  campoIndicador2="UA2"
                  campoIncremento="UA_INC"
                  category="utilidades"
                  adorno="$"
                  tipoIndicador={2}
                  nombreTarjeta="UA"
                />
                <TarjetaConOdometro
                  titulo="Utilidad neta"
                  campoIndicador1="UN1"
                  campoIndicador2="UN2"
                  campoIncremento="UN_INC"
                  category="utilidades"
                  adorno="$"
                  tipoIndicador={1}
                  activador="UN"
                />
              </Grid>
            </AccordionDetails>
          </Accordion>

        </AccordionDetails>
      </Accordion>

    </>
  );
};
export default TableroResultados;
