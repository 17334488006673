import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRecoilState, useRecoilValue } from 'recoil';
import AbrirCerrarMenu from '../Recoil/Atoms/AbrirCerrarMenu';
import { DataGrid } from '@mui/x-data-grid';
import userState from '../Recoil/Atoms/userState';
import {
    collection,
    getDocs,
    limitToLast,
    getFirestore,
    query,
    orderBy,
} from "firebase/firestore";
import firebaseApp from "../Firebase/firebaseSami";
import calculosState from '../Recoil/Atoms/CalculosState';
import iconosDeLista from '../Recoil/Atoms/iconosDeLista';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography'



const db = getFirestore(firebaseApp);






export default function DatosDeNubeDialog() {

    const user = useRecoilValue(userState);
    const [menu, setMenu] = useRecoilState(AbrirCerrarMenu);
    const [calculos, setCalculos] = useRecoilState(calculosState)
    const [tareas, setTareas] = useRecoilState(iconosDeLista);
    const [loadingButton, setLoadingButton] = React.useState(false)
    const [rows, setRows] = React.useState([]);


    const handleClose = () => {
        setMenu({ ...menu, nube: false });
    };


    const columns = [
        //{ field: 'id', headerName: 'ID', width: 20 },
        {
            field: 'date',
            headerName: 'Fecha de guardado',
            width: 390,
            editable: false,
        },
        {
            field: "seleccionar",
            headerName: " ",
            sortable: false,
            width: 80,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    seleccionar(params.row.timeStamp)
                };
                return (
                    <Tooltip placement="left" arrow title={<Typography variant="subtitle1">Descargar</Typography>}>
                        <Button
                            variant={loadingButton ? "outlined" : "contained"}
                            color="primary"
                            onClick={onClick}
                        >
                            <CloudDownloadIcon />
                        </Button>
                    </Tooltip>

                );
            }
        },
    ];







    const seleccionar = async (timeS) => {
        setLoadingButton(true)
        let datos = [];

        const q = query(collection(db, "usuarios5/" + user.email + "/datos"))
        const archivo = await getDocs(q);
        archivo.forEach((doc) => {
            datos.push(doc.data());
        })

        var seleccion = datos.find((item) => item.timeStamp.seconds == timeS.seconds);

        setCalculos(seleccion.calculos);
        setTareas(seleccion.tareas);
        localStorage.setItem("calculos", seleccion.calculos);
        localStorage.setItem("tareas", seleccion.tareas);

        setLoadingButton(false)
        handleClose();
    }

    const obtenerDatos = async (user) => {

        const diaDeLaSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
        const mesDelAño = [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre"
        ]
        let filas = []
        let idRow = 1;

        const q = query(collection(db, "usuarios5/" + user.email + "/datos"), orderBy("timeStamp"), limitToLast(50))
        const documentos = await getDocs(q);
        documentos.forEach((doc) => {
            try {
                const fecha = doc.data().timeStamp.toDate()
                const año = fecha.getFullYear();
                const dia = fecha.getDay();
                const diaNum = fecha.getDate();
                const mes = fecha.getMonth();
                const hora = fecha.getHours();
                let horaTexto = hora < 10 ? `0${hora}` : hora;
                const minutos = fecha.getMinutes();
                let minutosTexto = minutos < 10 ? `0${minutos}` : minutos;
                const segundos = fecha.getSeconds();
                let segundosTexto = segundos < 10 ? `0${segundos}` : segundos;
                let textoFecha = `${diaDeLaSemana[dia]}, ${diaNum} de ${mesDelAño[mes]} del ${año}, ${horaTexto}:${minutosTexto}:${segundosTexto}`;

                let añadirfila = {
                    id: idRow,
                    timeStamp: doc.data().timeStamp,
                    date: textoFecha
                }
                filas.push(añadirfila);
                idRow++;
            } catch (e) {
                console.warn(e)
            }
        })
        setRows(filas)

    }



    React.useEffect(() => {
        obtenerDatos(user)
    }, [menu])



    return (

        <Dialog
            open={menu.nube}
            onClose={handleClose}
            maxWidth='md'
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Escoge un elemento de la lista para cargar los datos:"}
            </DialogTitle>
            <DialogContent>
                <div style={{ height: 380, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}

                        rowsPerPageOptions={[10]}
                        hideFooterSelectedRowCount
                    />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loadingButton}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>

    );
}
