import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DraftsIcon from "@mui/icons-material/Drafts";
import ListItemLink from "./ListItemLink";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Divider from "@mui/material/Divider";


const ListaMenu = () => {
    return (
      <>
        <List>
          <ListItemLink
            to="/inbox"
            primary="Captar más clientes"
            icon={<InboxIcon />}
          />
          <ListItemLink
            to="/drafts"
            primary="Venderles más"
            icon={<DraftsIcon />}
          />
          <ListItemLink
            to="/ventas"
            primary="Generar más utilidades"
            icon={<DraftsIcon />}
          />
  
          {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  export default ListaMenu