import { atom } from "recoil";

const initialState = {
    DET_M: false,
    MOSTRARACCIONES: false,
    IM: true,
    P: true,
    TC: true,
    CA: true,
    CP: true,
    FC: true,
    MB: true,
    GF: true,
    IMP: true,
    MOSTRAR_INCREMENTO: true,
    MOSTRAR_REAL: true,
    MOSTRAR_META: true,
    CN: true,
    CPP: true,
    CPC: true,
    CT: true,
    VT: true,
    UB: true,
    UA: true,
    UN: true,
    PE: true,
    INDICADORES_DIALOG: false,
    SWITCHES_DIALOG: false,
    LOGIN_DIALOG: false,
    TAREAS_DIALOG: false
}

const layoutState = atom({
  key: "layoutState",
  default: initialState, 
});
export default layoutState;
