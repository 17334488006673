import { useState } from "react";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useRecoilState } from "recoil";
import userState from "../Recoil/Atoms/userState";
import CircularProgress from '@mui/material/CircularProgress';

import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import firebaseApp from '../Firebase/firebaseSami';
const auth = getAuth(firebaseApp);


export default function FormDialog() {
  const [open, setOpen] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [faltanDatos, setFaltanDatos] = useState(false);
  const [textoError, setTextoError] = useState("");
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useRecoilState(userState)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFaltanDatos(false)
    setOpen(false);
  };


  const confirmaContraseña = (e) => {
    setConfirmPassword(e.target.value);

  }


  const registerAction = async (e) => {
    e.preventDefault();
    if (nombre, apellido, email, password, confirmPassword != '') {
      setTextoError('');
      setFaltanDatos(false);

      if (password === confirmPassword) {
        setLoading(true)
        await createUserWithEmailAndPassword(auth, email, password)
          .then((result) => {
            const userResult = result.user


            let nombreCompleto = nombre + " " + apellido;
            updateProfile(auth.currentUser, {
              displayName: nombreCompleto
            });

            const newSesion = {
              logeado: true,
              uid: userResult.uid,
              nivel: "",
              nombre: nombreCompleto,
              email: userResult.email
            };

            setUser(newSesion);

            setNombre("");
            setApellido("");
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setLoading(false)

            handleClose();
          })
          .catch((error) => {
            console.log("Error de registro. Código de error: ", error.code, error.message);
          });
      } else {
        setFaltanDatos(true)
        setTextoError("- La confirmación de la contraseña no coincide");
      }
    } else {
      setFaltanDatos(true);
      setTextoError("- Faltan algunos datos");
    }
  };



  return (
    <div>
      <Button variant="outlined" fullWidth onClick={handleClickOpen}>
        Regístrate
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center" }}>Regístrate</DialogTitle>

        <DialogContent>
          <DialogContentText>Completa los siguientes datos:</DialogContentText>
          <form onSubmit={registerAction}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre"
              type="text"
              required
              fullWidth
              variant="standard"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />

            <TextField
              margin="dense"
              id="midName"
              label="Apellido"
              type="text"
              required
              fullWidth
              variant="standard"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
            />

            <TextField
              margin="dense"
              id="email"
              label="Correo electrónico"
              type="email"
              required
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              margin="dense"
              id="pwd"
              label="Contraseña"
              type="password"
              required
              fullWidth
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              margin="dense"
              id="confirm_pwd"
              label={
                (password === confirmPassword)
                  ? ((password === "")
                    ? "Confirmar contraseña"
                    : "Las contraseña coinciden"
                  )
                  : (
                    (confirmPassword === "")
                      ? "Confirmar contraseña"
                      : "Las contraseñas no coinciden"
                  )
              }
              color={
                (confirmPassword === '')
                  ? 'primary'
                  : (password === confirmPassword) ? "success" : "error"
              }
              type="password"
              required
              fullWidth
              variant="standard"
              value={confirmPassword}
              onChange={confirmaContraseña}
            />

            <br />
            <br />

            <Button
              fullWidth
              variant="contained"
              color={faltanDatos ? "error" : "primary"}
              type="submit"
              disabled={loading ? true : false}
              onClick={registerAction}
            >
              {loading
                ? (<CircularProgress size="25" />)
                : (faltanDatos ? `Enviar ${textoError}` : "Enviar")
              }
            </Button>
          </form>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
