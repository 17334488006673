import firebaseApp from '../../Firebase/firebaseSami';
import { getAuth, signInWithEmailAndPassword, } from "firebase/auth";
import ObtenerUltimosDatos from '../../../Firebase/ObtenerUltimosDatos';
const intentaLoginUsrPwd = async (setUser, menu, setMenu, email, pwd, setCalculos, setTareas, setCambiosNoGuardados) => {

    const auth = getAuth(firebaseApp);

    await signInWithEmailAndPassword(auth, email, pwd)
        .then((result) => {
            const user = result.user;

            const newSesion = {
                logeado: true,
                uid: user.uid,
                nivel: "",
                nombre: user.displayName,
                email: user.email
            };
            setUser(newSesion);
            setMenu({ ...menu, login: false });
            //compararDatos(newSesion, setCambiosNoGuardados);
            //ObtenerUltimosDatos(newSesion, setCalculos, setTareas)

        })
        .then(() => {

            try {
                let calculosLocal = localStorage.getItem('calculos');
                let calculosParsed;
                if (calculosLocal != null) {
                    calculosParsed = JSON.parse(calculosLocal)
                    try {
                        console.log(calculosParsed)
                        setCalculos(calculosParsed)
                    } catch (e) {
                        console.warn(e)
                    }

                }

                let tareasLocal = localStorage.getItem('tareas');
                let tareasParsed;
                if (tareasLocal != null) {
                    tareasParsed = JSON.parse(tareasLocal)
                    try {
                        setTareas(tareasParsed)
                    } catch (e) {
                        console.warn(e)
                    }
                }

                let localCambiosNoGuardados = localStorage.getItem('localCambiosNoGuardados')
                if (localCambiosNoGuardados === 'true') {
                    setCambiosNoGuardados(true)
                }
            } catch (error) {
                console.warn(error)
            }

        })
        .catch((error) => {
            console.log("Error en intenta login-->", error);
        });


}

export default intentaLoginUsrPwd;