import AppBarSami from "./AppBarSami";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import IndicadoresDialog from "../Tableros/IndicadoresDialog";
import LoginForm from "../Login/LoginForm";
import SwitchesTarjetas from "../Tableros/SwitchesTarjetas";
import MenuPrincipal from "./MenuPrincipal";
import TareasDialog from "./TareasDialog";
import userState from '../Recoil/Atoms/userState';
import { useRecoilValue, useRecoilState } from 'recoil';
import SnackbarCambiosSinGuardar from "./SnackbarCambiosSinGuardar";
import DatosDeNubeDialog from "./DatosDeNubeDialog";
import MenuIcon from "@mui/icons-material/Menu";
import AbrirCerrarMenu from '../Recoil/Atoms/AbrirCerrarMenu';
import cambiosNoGuardadosState from "../Recoil/Atoms/cambiosNoGuardadosState";


const ToolBarSami = ({ handleDrawerOpen, open }) => {

  

  const user = useRecoilValue(userState)
  const menu = useRecoilValue(AbrirCerrarMenu)
  const logeado = user.logeado

  let texto = "¡Bienvenido! - ";
  if (logeado) {
    if (user.nombre !== null) {
      texto = '¡Bienvenido, ' + user.nombre + '! - '
    }
  }



  return (
    <AppBarSami position="fixed" open={false}>
      <LoginForm navBar />
      {user.logeado &&
        <Toolbar>
          <MenuPrincipal />

          <IndicadoresDialog navBar />

          <SwitchesTarjetas />
          <TareasDialog />
          <SnackbarCambiosSinGuardar />
          <DatosDeNubeDialog />

          <Typography variant="h6" noWrap component="div">
            {
              logeado && texto
            }
            Los números atómicos de la PYME
          </Typography>
        </Toolbar>}
    </AppBarSami>
  );
};

export default ToolBarSami;
