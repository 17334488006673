import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const LortuItem = ({texto}) => {
    return (
        <ListItem disablePadding dense >
            
                <ListItemText primary={texto} sx={{textAlign:"center"}} />
            
        </ListItem>
    )
}

export default LortuItem
