import * as React from "react";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DrawerHeader from "./DrawerHeader";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ListaMenu from "./ListaMenu";



import { drawerWidth } from "./drawerWidth";


 const SideBarSami = ({ handleDrawerClose, open, theme }) => {
    return (
      <>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
  
          <ListaMenu />
        </Drawer>
      </>
    );
  };
  

  export default SideBarSami