import List from '@mui/material/List';
import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { useRecoilValue } from 'recoil';
import iconosDeLista from '../Recoil/Atoms/iconosDeLista';
import filtroTareaState from '../Recoil/Atoms/filtroTareaState'




export default function GeneradoresCard(props) {

    let backgroundColor;
    if (props.azul) backgroundColor = "#fafaff";
    if (props.verde) backgroundColor = "#fafffa";
    if (props.rojo) backgroundColor = "#fffafa";

    let colorDeTexto = "#000";
    if (props.azul) colorDeTexto = "#24f";
    if (props.verde) colorDeTexto = "#4a2";
    if (props.rojo) colorDeTexto = "#f24";

    let medidas = {
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4
    }
    if (props.grande) medidas = {
        sm: 12,
        md: 12,
        lg: 8,
        xl: 8
    }


    const ids = useRecoilValue(iconosDeLista)
    const filtro = useRecoilValue(filtroTareaState)

    let coinciden = []
    if (filtro) {
        if (props.numeros != null || undefined) {
            props.numeros.forEach((num) => {

                const n = ids.find((item) => item.id === num)
                if (n) { coinciden.push(num) }

            })
        }
        if (props.numeros == null || undefined) coinciden.push(1)
    }


    if ((filtro && coinciden.length > 0) || !filtro) {


        return (
            <Grid item sm={medidas.sm} md={medidas.md} lg={medidas.lg} xl={medidas.xl}>
                <Card sx={{ bgcolor: backgroundColor }}>
                    <CardContent>
                        <Box sx={{ display: "flex", alignItems: "center", }}>
                            <img src={props.img} />
                            <Typography ml={1} variant="body1" component="div" sx={{ color: colorDeTexto }} >
                                <b>{props.titulo}</b>
                            </Typography>
                        </Box>
                        {
                            props.grande
                                ? props.children

                                : <List dense disablePadding>
                                    {props.children}
                                </List>
                        }

                    </CardContent>
                </Card>
            </Grid>
        );

    } else return null
}
