import {
    collection,
    getDocs,
    limitToLast,
    getFirestore,
    query,
    orderBy,
} from "firebase/firestore";
import firebaseApp from "../Componentes/Firebase/firebaseSami";


const db = getFirestore(firebaseApp);



const ObtenerUltimosDatos = async (user, setCalculos, setTareas) => {


    const q = query(collection(db, "usuarios5/" + user.email + "/datos"), orderBy("timeStamp"), limitToLast(1))
    const ultimo = await getDocs(q);
    ultimo.forEach((doc) => {
        setCalculos(doc.data().calculos);
        setTareas(doc.data().tareas);
        localStorage.setItem('calculos', JSON.stringify(doc.data().calculos));
        localStorage.setItem('tareas', JSON.stringify(doc.data().tareas));
    })

}

export default ObtenerUltimosDatos