import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextFieldSami from "./TextFlieldSami";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tabla5Columnas from "./Tabla5Columnas";
import { useRecoilState, useRecoilValue } from "recoil";
import AbrirCerrarMenu from "../Recoil/Atoms/AbrirCerrarMenu";
import calculosState from "../Recoil/Atoms/CalculosState";
const theme = createTheme({
  palette: {
    secondary: {
      main: "#2a2aaa",
    },
  },
});

export default function IndicadoresDialog({ navBar = false }) {

  const indicadores = useRecoilValue(calculosState);

  const [menu, setMenu] = useRecoilState(AbrirCerrarMenu)

  //const [open, setOpen] = useState(abierto);


  const handleClickOpen = () => {

  };

  const handleClose = () => {
    //action('INDICADORES_TRIGGER')
    setMenu({ ...menu, tabla: !menu.tabla })
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        {/* <Button
          sx={{ marginRight: "10px" }}
          variant="contained"
          color={navBar ? "secondary" : "primary"}
          onClick={handleClickOpen}
        >
          {" "}
          <AssessmentOutlinedIcon />
          Tabla de datos
        </Button> */}
      </ThemeProvider>
      <Dialog open={menu.tabla} onClose={handleClose} maxWidth={'md'} >
        <DialogTitle>Indicadores</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escribir solo un dato de esto actualizará al instante todo lo demás.
          </DialogContentText>
          <br />

          {/* Marketing */}
          <Accordion sx={{ background: "#fafaff" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: "100px", flexShrink: 0 }}>
                <b>Marketing</b>
              </Typography>
              <Typography sx={{ color: "indigo" }}>
                Clientes nuevos: {indicadores.CN2}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tabla5Columnas
                col1=""
                col2="Anterior"
                col3="Variación"
                col4="Meta"
                col5="Real"
              />

              {/* Inversión en marketing */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Inversión en marketing
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="IM1"
                  adorno="$"
                  value={indicadores.IM1}
                />
                <TextFieldSami
                  nombreCampo="IM_INC"
                  adorno="%"
                  value={indicadores.IM_INC}
                />
                <TextFieldSami
                  nombreCampo="IM2"
                  adorno="$"
                  value={indicadores.IM2}
                />
                <TextFieldSami
                  nombreCampo="IMR"
                  adorno="$"
                  value={indicadores.IMR}
                />
              </Grid>

              {/* Prospectos */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Prospectos
                  </Typography>
                </Grid>

                <TextFieldSami
                  nombreCampo="P1"
                  titulo="Prospectos 1"
                  adorno="none"
                  value={indicadores.P1}
                />
                <TextFieldSami
                  nombreCampo="P_INC"
                  adorno="%"
                  value={indicadores.P_INC}
                />
                <TextFieldSami
                  nombreCampo="P2"
                  adorno="none"
                  value={indicadores.P2}
                />
                <TextFieldSami
                  nombreCampo="PR"
                  adorno="none"
                  value={indicadores.PR}
                />
              </Grid>

              {/* Tasa de conversión */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Tasa de conversión
                  </Typography>
                </Grid>

                <TextFieldSami
                  nombreCampo="TC1"
                  adorno="%"
                  value={indicadores.TC1}
                />
                <TextFieldSami
                  nombreCampo="TC_INC"
                  adorno="%"
                  value={indicadores.TC_INC}
                />
                <TextFieldSami
                  nombreCampo="TC2"
                  adorno="%"
                  value={indicadores.TC2}
                />
                <TextFieldSami
                  nombreCampo="TCR"
                  adorno="%"
                  value={indicadores.TCR}
                />
              </Grid>

              {/* Clientes nuevos */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Clientes nuevos
                  </Typography>
                </Grid>

                <TextFieldSami
                  nombreCampo="CN1"
                  adorno="none"
                  value={indicadores.CN1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CN_INC"
                  adorno="%"
                  value={indicadores.CN_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CN2"
                  adorno="none"
                  value={indicadores.CN2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CNR"
                  adorno="none"
                  value={indicadores.CNR}
                />
              </Grid>

              {/* Costo por prospecto */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Costo por prospecto
                  </Typography>
                </Grid>

                <TextFieldSami
                  nombreCampo="CPP1"
                  adorno="$"
                  value={indicadores.CPP1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CPP_INC"
                  adorno="%"
                  value={indicadores.CPP_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CPP2"
                  adorno="$"
                  value={indicadores.CPP2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CPPR"
                  adorno="$"
                  value={indicadores.CPPR}
                />
              </Grid>

              {/* Costo por cliente */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Costo por cliente
                  </Typography>
                </Grid>

                <TextFieldSami
                  nombreCampo="CPC1"
                  adorno="$"
                  value={indicadores.CPC1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CPC_INC"
                  adorno="%"
                  value={indicadores.CPC_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CPC2"
                  adorno="$"
                  value={indicadores.CPC2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CPCR"
                  adorno="$"
                  value={indicadores.CPCR}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Ventas */}
          <Accordion sx={{ background: "#fafffa" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ width: "100px", flexShrink: 0 }}>
                <b>Ventas</b>
              </Typography>
              <Typography sx={{ color: "green" }}>
                Ventas totales: $ {indicadores.VT2}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tabla5Columnas
                col1=""
                col2="Anterior"
                col3="Variación"
                col4="Meta"
                col5="Real"
              />

              {/* Clientes anteriores */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Clientes anteriores
                  </Typography>
                </Grid>

                <TextFieldSami
                  nombreCampo="CA1"
                  adorno="none"
                  value={indicadores.CA1}
                />
                <TextFieldSami
                  nombreCampo="CA_INC"
                  adorno="%"
                  value={indicadores.CA_INC}
                />
                <TextFieldSami
                  nombreCampo="CA2"
                  adorno="none"
                  value={indicadores.CA2}
                />
                <TextFieldSami
                  nombreCampo="CAR"
                  adorno="none"
                  value={indicadores.CAR}
                />
              </Grid>

              {/* Clientes totales */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Clientes totales
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="CT1"
                  adorno="none"
                  value={indicadores.CT1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CT_INC"
                  adorno="%"
                  value={indicadores.CT_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CT2"
                  adorno="none"
                  value={indicadores.CT2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="CTR"
                  adorno="none"
                  value={indicadores.CTR}
                />
              </Grid>

              {/* Ticket promedio */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Compra promedio
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="CP1"
                  adorno="$"
                  value={indicadores.CP1}
                />
                <TextFieldSami
                  nombreCampo="CP_INC"
                  adorno="%"
                  value={indicadores.CP_INC}
                />
                <TextFieldSami
                  nombreCampo="CP2"
                  adorno="$"
                  value={indicadores.CP2}
                />
                <TextFieldSami
                  nombreCampo="CPR"
                  adorno="$"
                  value={indicadores.CPR}
                />
              </Grid>

              {/* Frecuencia de compra */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Frecuencia de compra
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="FC1"
                  titulo="Frecuencia de compra 1"
                  adorno="none"
                  value={indicadores.FC1}
                />
                <TextFieldSami
                  nombreCampo="FC_INC"
                  adorno="%"
                  value={indicadores.FC_INC}
                />
                <TextFieldSami
                  nombreCampo="FC2"
                  adorno="none"
                  value={indicadores.FC2}
                />
                <TextFieldSami
                  nombreCampo="FCR"
                  adorno="none"
                  value={indicadores.FCR}
                />
              </Grid>

              {/* Ventas totales */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Ventas totales
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="VT1"
                  adorno="$"
                  value={indicadores.VT1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="VT_INC"
                  adorno="%"
                  value={indicadores.VT_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="VT2"
                  adorno="$"
                  value={indicadores.VT2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="VTR"
                  adorno="$"
                  value={indicadores.VTR}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Utilidad */}
          <Accordion sx={{ background: "#fffafa" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography sx={{ width: "100px", flexShrink: 0 }}>
                <b>Utilidad</b>
              </Typography>
              <Typography sx={{ color: "red" }}>
                Utilidad neta: $ {indicadores.UN2}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tabla5Columnas
                col1=""
                col2="Anterior"
                col3="Variación"
                col4="Meta"
                col5="Real"
              />

              {/* Margen bruto */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Margen bruto
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="MB1"
                  adorno="$"
                  value={indicadores.MB1}
                />
                <TextFieldSami
                  nombreCampo="MB_INC"
                  adorno="%"
                  value={indicadores.MB_INC}
                />
                <TextFieldSami
                  nombreCampo="MB2"
                  adorno="$"
                  value={indicadores.MB2}
                />
                <TextFieldSami
                  nombreCampo="MBR"
                  adorno="$"
                  value={indicadores.MBR}
                />
              </Grid>

              {/* Utilidad bruta */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Utilidad bruta
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="UB1"
                  adorno="$"
                  value={indicadores.UB1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UB_INC"
                  adorno="%"
                  value={indicadores.UB_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UB2"
                  adorno="$"
                  value={indicadores.UB2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UBR"
                  adorno="$"
                  value={indicadores.UBR}
                />
              </Grid>

              {/* Gastos fijos */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Gastos fijos
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="GF1"
                  adorno="$"
                  value={indicadores.GF1}
                />
                <TextFieldSami
                  nombreCampo="GF_INC"
                  adorno="%"
                  value={indicadores.GF_INC}
                />
                <TextFieldSami
                  nombreCampo="GF2"
                  adorno="$"
                  value={indicadores.GF2}
                />
                <TextFieldSami
                  nombreCampo="GFR"
                  adorno="$"
                  value={indicadores.GFR}
                />
              </Grid>

              {/* Utilidad antes de impuestos */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Utilidad antes de impuestos
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="UA1"
                  adorno="$"
                  value={indicadores.UA1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UA_INC"
                  adorno="%"
                  value={indicadores.UA_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UA2"
                  adorno="$"
                  value={indicadores.UA2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UAR"
                  adorno="$"
                  value={indicadores.UAR}
                />
              </Grid>

              {/* Impuestos */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Impuestos
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="IMP1"
                  adorno="$"
                  value={indicadores.IMP1}
                />
                <TextFieldSami
                  nombreCampo="IMP_INC"
                  adorno="%"
                  value={indicadores.IMP_INC}
                />
                <TextFieldSami
                  nombreCampo="IMP2"
                  adorno="$"
                  value={indicadores.IMP2}
                />
                <TextFieldSami
                  nombreCampo="IMPR"
                  adorno="$"
                  value={indicadores.IMPR}
                />
              </Grid>

              {/* Utilidad neta */}
              <Grid
                container
                spacing={1}
                columns={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item sm={1}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Utilidad neta
                  </Typography>
                </Grid>
                <TextFieldSami
                  nombreCampo="UN1"
                  adorno="$"
                  value={indicadores.UN1}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UN_INC"
                  adorno="%"
                  value={indicadores.UN_INC}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UN2"
                  adorno="$"
                  value={indicadores.UN2}
                  desactivado
                />
                <TextFieldSami
                  nombreCampo="UNR"
                  adorno="$"
                  value={indicadores.UNR}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
