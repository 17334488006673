import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GeneradoresDeRiqueza from '../Tableros/GeneradoresDeRiqueza'
import AbrirCerrarMenu from '../Recoil/Atoms/AbrirCerrarMenu';
import { useRecoilState } from 'recoil';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import filtroTareaState from '../Recoil/Atoms/filtroTareaState';

export default function TareasDialog() {

  const [menu, setMenu] = useRecoilState(AbrirCerrarMenu)
  const [filtroTareas, setFiltroTareas] = useRecoilState(filtroTareaState)



  const handleClose = () => {
    setMenu({ ...menu, acciones: !menu.acciones })
  };

  return (

    <Dialog
      open={menu.acciones}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        Generadores de riqueza en las PYMEs
      </DialogTitle>

      <DialogContent>
        <GeneradoresDeRiqueza />
      </DialogContent>

      <DialogActions>
        <FormControlLabel
          sx={{ marginLeft: "auto" }}
          control={<Switch
            checked={filtroTareas}
            onClick={() => { setFiltroTareas(!filtroTareas) }} />}
          label="Ocultar tareas no marcadas" />
        <Button onClick={handleClose} autoFocus>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>

  );
}