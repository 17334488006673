import React from "react";
import IconButton from "@mui/material/IconButton";
import { useRecoilState } from "recoil";
import layoutState from "../Recoil/Atoms/layoutState";
import CloseIcon from "@mui/icons-material/Close";

import crearNuevoJson from '../Recoil/funciones/crearNuevoJson'

const BotonCerrarTarjeta = ({ nombreTarjeta }) => {


  const [layout, setLayout] = useRecoilState(layoutState);


  /* const crearNuevoJson = () => {
    var obj = {};
    obj[nombreTarjeta] = !layout[nombreTarjeta];
    
    setLayout({ ...layout, ...obj })
  }
*/
  

  

  const cerrar = () =>{
    let obj = crearNuevoJson(nombreTarjeta, !layout[nombreTarjeta])
    setLayout({...layout, ...obj})
  }


  return (
    <IconButton
      aria-label="close"
      sx={{ marginLeft: "auto", marginRight: "0px" }}
      onClick={() => {
        cerrar()
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default BotonCerrarTarjeta;
