import React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useRecoilState } from "recoil";
import calculosState from "../Recoil/Atoms/CalculosState";
import procesaRecalculos from '../Recoil/funciones/procesaRecalculos'
import cambiosNoGuardadosState from "../Recoil/Atoms/cambiosNoGuardadosState";

//const StyledTextField = styled("TextField")({});
const theme = createTheme({
  palette: {
    secondary: {
      main: "#aaa",
    },
  },
});

export default function TextFieldSami({
  nombreCampo,
  adorno,
  desactivado = false,
}) {

  const [indicadores, setIndicadores] = useRecoilState(calculosState);
  const valueRedux = indicadores[nombreCampo];
  const [valorLocal, setValorLocal] = React.useState(valueRedux);

  const [cambiosNoGuardados, setCambiosNoGuardados] = useRecoilState(cambiosNoGuardadosState)


  let inp = null;
  if (adorno === "$") {
    inp = {
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    };
  } else if (adorno === "%") {
    inp = {
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    };
  }


  useEffect(() => {
    setValorLocal(valueRedux);
  }, [valueRedux]);


  const lostFocus = (e) => {
    if (e) {
      if (e.target.value != valueRedux) {
        procesaRecalculos(
          nombreCampo,
          e.target.value,
          indicadores,
          setIndicadores,
          setCambiosNoGuardados
        );
      }
    }
  };

  const handleOnChange = (e) => {
    setValorLocal(e.target.value);
  }

  return (
    <Grid item sm={1}>
      {desactivado ? (
        <Box
          sx={{
            border: "solid 1px #aaa",
            height: "41px",
            borderRadius: "3px",
            position: "relative",
            bottom: "-3px",
          }}
        >

          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", position: "relative", bottom: "-5px" }}
          >
            {adorno == "$" && "$ "}
            {valorLocal}
            {adorno == "%" && " %"}
          </Typography>
        </Box>
      ) : (
        <TextField
          variant="outlined"
          fullWidth
          focused
          margin="normal"
          size="small"
          color="info"
          id={nombreCampo}
          type="number"
          InputProps={inp}
          value={valorLocal}
          onChange={handleOnChange}
          onBlur={lostFocus}
        />
      )}
    </Grid>
  );
}
