import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useRecoilState, useRecoilValue } from 'recoil';
import calculosState from '../Recoil/Atoms/CalculosState';
import IndicadorSami from "./IndicadorSami";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OdometroTripe from "./OdometroTripe";
import crearNuevoJson from '../Recoil/funciones/crearNuevoJson';
import layoutState from '../Recoil/Atoms/layoutState'
import BotonCerrarTarjeta from "./BotonCerrarTarjeta";


function TarjetaConOdometro({
  titulo,
  campoIncremento,
  campoIndicador1,
  campoIndicador2,
  campoIndicador3,
  category,
  nombreTarjeta,
  tipoIndicador = 1,
  triple = true,
}) {
  
  const switchTarjeta = () => {
    console.log("falta")
  }

  const ultimoResultado = useRecoilValue(calculosState);
  const [layout, setLayout] = useRecoilState(layoutState);
  let mostrarTarjeta = layout;

  const cerrar = () =>{
    let obj = crearNuevoJson(nombreTarjeta, !layout[nombreTarjeta])
    setLayout({...layout, ...obj})
  }

  let incremento = ultimoResultado[campoIncremento];
  let indicador1 = ultimoResultado[campoIndicador1];
  let indicador2 = ultimoResultado[campoIndicador2];
  let indicador3 = ultimoResultado[campoIndicador3];

  /*
  console.log(
    "Soy tarjeta con indicador campos:",
    campoIndicador1,
    campoIndicador2,
    campoIndicador3
  );

  console.log(
    "Soy tarjeta con indicador valores:",
    indicador1,
    indicador2,
    indicador3
  );
*/
  if (incremento < 0) incremento = incremento * -1;

  //Colores
  let backgroundColor;
  if (category === "marketing") {
    backgroundColor = "#fafaff";
  }
  if (category === "ventas") {
    backgroundColor = "#fafffa";
  }
  if (category === "utilidades") {
    backgroundColor = "#fffafa";
  }

  return (
    <>
      {mostrarTarjeta[nombreTarjeta] && (
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Card
            mb={3}
            sx={{
              background: backgroundColor,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                {titulo}
              </Typography>

              <BotonCerrarTarjeta nombreTarjeta={nombreTarjeta} />
            </Box>

            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "150%",
                  position: "relative"

                }}
              >
                {triple ? (
                  <OdometroTripe
                    tipoIndicador={tipoIndicador}
                    valor1={indicador1}
                    valor2={indicador2}
                    valor3={indicador3}
                  />
                ) : (
                  <IndicadorSami
                    tipoIndicador={tipoIndicador}
                    valor={indicador1}
                  />
                )}
              </Box>
            </CardContent>

          </Card>
        </Grid>
      )}
    </>
  );
}

export default TarjetaConOdometro;
