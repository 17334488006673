import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';


const GeneradoresAcordeon = (props) => {
    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={props.img} width="20px" />
                        <Typography ml={2}>{props.titulo}</Typography>
                    </Box>
                </AccordionSummary>


                <AccordionDetails>
                    <Grid container spacing={1} sx={{ display: "flex", justifyContent: "start" }} >
                        {props.children}
                    </Grid>
                </AccordionDetails>


            </Accordion>
        </>
    )
}

export default GeneradoresAcordeon
